import { Admin } from "types/Admin";
import { signal } from "@preact/signals-react";


export type AdminCollection = {
  admins: Admin[];
};

export const adminStore = signal<AdminCollection>(
  {
    admins: [],
  }
);
