import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { getFeedback } from "apis/feedback/get.feedback";
import { useEffect, useState } from "react";
import { userFeedback } from "types/Feedback";

const sampleData: userFeedback[] = [
  {
    _id: "",
    name: "",
    email: "",
    message: "",
    createdAt: new Date(),
  },
];
const Feedback = () => {
  const [feedback, setFeedback] = useState(sampleData);
  useEffect(() => {
    getFeedback({
      onCompleted: (data) => {

        setFeedback(data);
      },
      onError: () => {
        //
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TableContainer bg={"white"} borderRadius={"0.625rem"}>
      <Table variant="simple">
        <TableCaption>User Feedback</TableCaption>
        <Thead>
          <Tr>
            <Th color={"black"}>Date</Th>
            <Th color={"black"}>Email</Th>
            <Th color={"black"}>Title</Th>
            <Th color={"black"}>Message</Th>
          </Tr>
        </Thead>
        <Tbody color={"black"}>
          {feedback.map(({ _id, name, email, message, createdAt }) => (
            <Tr color={"black"} key={_id}>
              <Td>{new Date(createdAt).toDateString()}</Td>
              <Td>{email}</Td>
              <Td>{name}</Td>
              <Td>{message}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
export default Feedback;
