import { AddIcon } from '@chakra-ui/icons'
import { Box, Flex, Image, Spinner, useColorMode } from '@chakra-ui/react'
import AvatarImage from 'components/AvatarImage'
import ImageModal from 'components/AppModals/ImageModal'
import ResponsiveModal from 'components/ResponsiveModal'
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  type FC,
  useState,
} from 'react'
import UnSplashModal from 'components/AppModals/UnSplashModal'
import GiphyModal from 'components/AppModals/GiphyModal'
import { type IGif } from '@giphy/js-types'
import ConfirmationModal from 'components/AppModals/ConfirmationModal'

interface IUploadImage {
  showImageModal: boolean
  image: string | ArrayBuffer | null
  toggleModal: () => void
  fromThirdParty: (data: any) => void
  fromGallery: (data: any) => void
  placeholder?: any
  w?: string
  h?: string
  minW?: string
  minH?: string
  maxW?: string
  isSmall?: boolean
  isLoading?: boolean
  modalType: 'image modal' | 'unsplash' | 'giphy'
  setModalType: Dispatch<SetStateAction<'image modal' | 'unsplash' | 'giphy'>>
  isFull?: boolean
  imageShape?: 'round' | 'straight' | 'curved' | 'curved-md'
  handleRemoveImg?: () => void
  imageIsNotCompulsory?: boolean
  shouldUseConfirmationModal?: boolean
  border?: string
  addColor?: string
}

const UploadImage: FC<IUploadImage> = ({
  fromGallery,
  fromThirdParty,
  w,
  h,
  minW,
  minH,
  maxW,
  image,
  placeholder,
  showImageModal,
  toggleModal,
  isLoading,
  modalType,
  setModalType,
  isFull,
  isSmall,
  imageShape,
  handleRemoveImg,
  imageIsNotCompulsory,
  shouldUseConfirmationModal,
  border,
  addColor,
}: IUploadImage) => {
  const { colorMode } = useColorMode()
  const [openDeleteDrawer, setOpenDeleteDrawer] = useState(false)

  const selectThirdPartyImage = useCallback(
    (url: string) => {
      fromThirdParty(url)
    },
    [fromThirdParty],
  )

  const handleRemoveImage = (): void => {
    handleRemoveImg?.()
    setOpenDeleteDrawer(false)
  }
  const imageBorderRadius = {
    round: '50%',
    straight: '0',
    curved: '10px',
    'curved-md': '20px',
  }[imageShape as 'round' | 'straight' | 'curved' | 'curved-md']

  const thisImageBorderRadius = imageBorderRadius ?? '50%'

  return (
    <Flex
      w={'full'}
      h={'inherit'}
      maxW={{ md: maxW ?? (isFull ? 'full' : '150px') }}
      position={'relative'}
    >
      <Flex
        borderRadius={thisImageBorderRadius}
        border={border ?? '2px solid #4a4a4a'}
        borderStyle={'dashed'}
        w={w}
        h={h ?? 'full'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box
          position="relative"
          borderRadius={thisImageBorderRadius}
          backgroundSize={'cover'}
          bgPos={'center'}
          bg={`url(${placeholder})`}
          backgroundRepeat={'no-repeat'}
          w={{ base: 'full', md: isFull ? 'full' : w ?? '120px' }}
          h={{ base: 'full', md: isFull ? '280px' : h ?? '120px' }}
          p={{ base: '20px', md: '20px' }}
          maxW={'inherit'}
          minW={isFull ? '250px' : minW ?? '80px'}
          minH={isFull ? '250px' : minH ?? '80px'}
          maxHeight={isFull ? '450px' : '150px'}
        >
          <Flex
            justifyContent={'center'}
            alignItems="center"
            position="absolute"
            top={'50%'}
            left={'50%'}
            transform={'translateY(-50%) translateX(-50%)'}
            w={{ base: '14px', md: '20px' }}
            h={{ base: '14px', md: '20px' }}
            cursor="pointer"
            onClick={toggleModal}
            borderRadius={'50%'}
          >
            <AddIcon
              position={'absolute'}
              zIndex={'overlay'}
              transform={'auto'}
              w={'100%'}
              h={'full'}
              color={addColor ?? (colorMode === 'light' ? 'white' : '#F5CE6F')}
            />
            {image && (
              <AvatarImage
                image={image ?? placeholder}
                filterValue="blur(1px)"
                w={`calc(${w} - 2px)`}
                h={`calc(${w} - 2px)`}
                imageBorderRadius={thisImageBorderRadius}
                border="none"
              />
            )}
          </Flex>
          <ResponsiveModal
            isOpen={showImageModal}
            toggle={toggleModal}
            modalType="upload Image"
            maxWidth={'25rem'}
            width={'100%'}
            bg={colorMode === 'light' ? '#f1f1f1' : '#111'}
            // contentStyles={{ maxWidth: '25rem' }}
            content={
              modalType === 'unsplash' ? (
                <UnSplashModal
                  selectUnsplashImage={imageUrl => {
                    selectThirdPartyImage(imageUrl)
                  }}
                  onClose={() => {
                    setModalType("image modal")
                  }}
                />
              ) : modalType === 'giphy' ? (
                <GiphyModal
                  maxWidth={398}
                  // maxWidth={698}
                  selectGiphyImage={(gif: IGif) => {
                    selectThirdPartyImage(gif.images.original.url)
                  }}
                  onClose={() => {
                    setModalType("image modal")
                  }}
                />
              ) : (
                <ImageModal
                  fromThirdParty={fromThirdParty}
                  fromGallery={fromGallery}
                  setModalType={setModalType}
                />
              )
            }
          />
          <Box
            pos={'absolute'}
            w={'full'}
            h={'full'}
            top={0}
            left={0}
            display={isLoading ? 'flex' : 'none'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {isLoading ? (
              <Spinner
                color={colorMode === 'light' ? 'black' : 'white'}
                w={{ base: '20px', md: '30px' }}
                h={{ base: '20px', md: '30px' }}
              />
            ) : (
              ''
            )}
          </Box>
        </Box>
      </Flex>
      {image && imageIsNotCompulsory ? (
        <>
          <Flex
            width={'24px'}
            height={'24px'}
            rounded={'full'}
            zIndex={5}
            bottom={isSmall ? '-2px' : '5px'}
            right={'1px'}
            // bg={darkBg}
            onClick={() => {
              setOpenDeleteDrawer(true)
              if (!shouldUseConfirmationModal) {
                handleRemoveImage()
              }
            }}
            position={'absolute'}
          >
            <Image
              src={'/svgs/delete.svg'}
              w={'full'}
              h={'full'}
              alt={'delete'}
              onClick={(e: any) => {}}
              cursor={'pointer'}
              loading="lazy"
            />
          </Flex>
        </>
      ) : (
        <></>
      )}
      {shouldUseConfirmationModal ? (
        <ConfirmationModal
          text="Are you sure you want to remove this image?"
          openModal={openDeleteDrawer}
          toggleModal={() => {
            setOpenDeleteDrawer(false)
          }}
          handleRemoveImg={handleRemoveImage}
        />
      ) : (
        <></>
      )}
    </Flex>
  )
}

export default UploadImage
