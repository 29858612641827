import { type LinkItemProps } from "typings/interface";

const LinkItems: LinkItemProps[] = [
  { name: "Dashboard", route: "/" },
  // { name: "Editor", route: "/template-editor" },
  { name: "Templates", route: "/templates" },
];

const Menu = {
  LinkItems
}

export default Menu;
