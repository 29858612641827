import {
  Flex,
  Text,
  useToast,
  useColorMode,
  ColorMode,
  Checkbox,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

// import { updateCurrentUser } from 'hooks/useCurrentUser'
import React, { useState, type FC, useCallback } from "react";
// import { type GetIndividualV2 } from 'routes/AuthRoutesProvider'
import theme from "theme";
// import { POST } from 'utils/base.api'
import {
  TfiLayoutWidthDefault,
  TfiLayoutWidthDefaultAlt,
} from "react-icons/tfi";
import Button from "components/Button";
import { PlainInput } from "components/Input";
import { createDefaultTemplate } from "apis/templates/create.default.template";
import { createTemplateFromPeige } from "apis/templates/create.template.from.peige";
import {
  setCurrentTemplate,
  templatesStore,
} from "utils/store/templates";
import UploadImage from "components/UploadImage";
import TextArea from "components/TextArea";

const lightIconBg = theme.colors.shareModal.light.iconBg;
const darkIconBg = theme.colors.shareModal.dark.iconBg;

interface ICreateTemplateModal {
  // username: string
  // maxWidth: any
}

const CreateTemplateModal: FC<ICreateTemplateModal> = () => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });
  const navigate = useNavigate();

  const { colorMode } = useColorMode();

  const [templateCreateMode, setTemplateCreateMode] = useState<
    "default" | "peige"
  >("default");
  const [templateData, setTemplateData] = useState({
    templateName: "",
    peigeUsername: "",
    description: "",
  });
  const [imgLoading] = useState(false);
  const [addTemplatetToApp, shouldAddTemplatetToApp] =
    useState(false);
  const [image, setImage] = useState<string | ArrayBuffer | null>("");
  const [, setFile] = useState<File | string>("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [modalType, setModalType] = useState<
    "image modal" | "unsplash" | "giphy"
  >("image modal");

  const fromThirdParty = useCallback((imageUrl: string) => {
    setImage(imageUrl);
    setFile(imageUrl);
    setShowImageModal(false);
    setModalType("image modal");
  }, []);

  const fromGallery = useCallback((file: File) => {
    setFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
    setShowImageModal(false);
    setModalType("image modal");
  }, []);

  const handleUsernameChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    e.preventDefault();
    if (e.target.value.length <= 20) {
      setTemplateData({
        ...templateData,
        peigeUsername: e.target.value
          .toLowerCase()
          .replace(/\s+/g, "")
          .replace(/[^a-zA-Z0-9]/g, ""),
      });
    }
  };

  const handleDescriptionChange: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = (e) => {
    e.preventDefault();
    setTemplateData({
      ...templateData,
      description: e.target.value,
    });
  };

  const handleTemplateNameChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    e.preventDefault();
    if (e.target.value.length <= 20) {
      setTemplateData({
        ...templateData,
        templateName: e.target.value
          .toLowerCase()
          .replace(/\s+/g, "")
          .replace(/[^a-zA-Z0-9]/g, ""),
      });
    }
  };

  return (
    <Flex
      flexDir={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      fontFamily={"DM Sans, sans-serif"}
      pt={"0.5rem"}
      pb={"1.3rem"}
      className="no-scrollbar"
      // maxW={{base:'26.75rem', lg:'30rem'}}
      // maxH={{ base: '20.375rem', md: '21.25rem' }}
      overflowY={"auto"}
    >
      <Text fontSize="1rem" fontWeight={700} mb={"2rem"}>
        Create a new template
      </Text>
      <Flex gap={"1.25rem"}>
        <TemplateChoiceButton
          choice={"blank canvas"}
          choiceAction={() => {
            setTemplateCreateMode("default");
          }}
          choiceActive={templateCreateMode === "default"}
          colorMode={colorMode}
          Icon={<TfiLayoutWidthDefaultAlt />}
        />
        <TemplateChoiceButton
          choice={"from a peige"}
          choiceAction={() => {
            setTemplateCreateMode("peige");
          }}
          choiceActive={templateCreateMode === "peige"}
          colorMode={colorMode}
          Icon={<TfiLayoutWidthDefault />}
        />
      </Flex>
      <Flex
        w={"100%"}
        flexDir={"column"}
        mt={"1.88rem"}
        gap={"0.62rem"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Checkbox
          isChecked={addTemplatetToApp}
          onChange={(e) => {
            shouldAddTemplatetToApp(e.target.checked);
          }}
        >
          Add template to App
        </Checkbox>
        <UploadImage
          fromGallery={fromGallery}
          fromThirdParty={fromThirdParty}
          w={"120px"}
          h={"120px"}
          minW={"115px"}
          minH={"115px"}
          image={image}
          placeholder={"/images/upload-image.webp"}
          showImageModal={showImageModal}
          imageIsNotCompulsory={true}
          //   setShowImageModal={setShowImageModal}
          handleRemoveImg={() => {
            setImage("");
            setFile("");
          }}
          isLoading={imgLoading}
          toggleModal={() => {
            const isOff = !showImageModal;
            setShowImageModal(!showImageModal);
            if (isOff) setModalType("image modal");
          }}
          modalType={modalType}
          setModalType={setModalType}
          imageShape={"straight"}
        />
        {templateCreateMode === "peige" ? (
          <Flex
            // bgColor={colorMode === 'light' ? lightIconBg : darkLinkBg}
            py="0.75rem"
            pr={"1.38rem"}
            pl={{ base: "1rem", md: "1.12rem" }}
            borderRadius="0.5rem"
            maxWidth={{ base: "23rem", md: "25rem" }}
            // h={"3rem"}
            w="100%"
            justifyContent={"space-between"}
            alignItems={"center"}
            m={"auto"}
            flexDir={"column"}
          >
            <PlainInput
              name={"username"}
              placeholder="Enter peige username"
              value={templateData.peigeUsername}
              onChange={handleUsernameChange}
              background={colorMode === "light" ? "#FFFFFF" : "#222"}
              color={colorMode === "light" ? "#111" : "#F1F1F1"}
              _placeholder={
                colorMode === "light" ? "#111" : "#F1F1F1"
              }
              borderColor={
                colorMode === "light"
                  ? "rgba(0, 0, 0, 0.5)"
                  : "rgba(255, 255, 255, 0.5)"
              }
            />
          </Flex>
        ) : (
          <></>
        )}
        <Flex
          // bgColor={colorMode === 'light' ? lightIconBg : darkLinkBg}
          py="0.75rem"
          pr={"1.38rem"}
          pl={{ base: "1rem", lg: "1.12rem" }}
          borderRadius="0.5rem"
          maxWidth={{ base: "23rem", md: "25rem" }}
          // h={"3rem"}
          w="100%"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={"auto"}
          flexDir={"column"}
        >
          <PlainInput
            name={"template_name"}
            placeholder="Enter Template Name"
            value={templateData.templateName}
            onChange={handleTemplateNameChange}
            background={colorMode === "light" ? "#FFFFFF" : "#222"}
            color={colorMode === "light" ? "#111" : "#F1F1F1"}
            _placeholder={colorMode === "light" ? "#111" : "#F1F1F1"}
            borderColor={
              colorMode === "light"
                ? "rgba(0, 0, 0, 0.5)"
                : "rgba(255, 255, 255, 0.5)"
            }
          />
          <TextArea
            placeholder="Add a description"
            name={"template_description"}
            onChange={handleDescriptionChange}
            value={templateData.description}
            background={colorMode === "light" ? "#FFFFFF" : "#222"}
            color={colorMode === "light" ? "#111" : "#F1F1F1"}
            border={"1px solid transparent"}
          />
        </Flex>

        <Button
          text={"create"}
          isDisabled={
            templateCreateMode === "default"
              ? !templateData.templateName
              : templateCreateMode === "peige"
              ? !(
                  templateData.templateName &&
                  templateData.peigeUsername
                )
              : true
          }
          onClick={() => {
            if (templateCreateMode === "default") {
              createDefaultTemplate({
                body: {
                  templateName: templateData.templateName,
                  description: templateData.description,
                  isInApp: addTemplatetToApp,
                  coverImage: image as string,
                },
                onCompleted(data) {
                  templatesStore.value = {
                    ...templatesStore.value,
                    currentTemplateID: data.templateID,
                  };
                  setCurrentTemplate({templateID: data.templateID, templateName: data.templateName, shortID: data.shortID});
                  navigate("/template-editor");
                  toast({
                    status: "success",
                    description: "new template created",
                  });
                },
                onError(err) {
                  toast({
                    status: "error",
                    description: err,
                  });
                },
              });
            } else {
              createTemplateFromPeige({
                body: {
                  templateName: templateData.templateName,
                  peigeUsername: templateData.peigeUsername,
                  description: templateData.description,
                  isInApp: addTemplatetToApp,
                  coverImage: image as string,
                },
                onCompleted(data) {
                  templatesStore.value = {
                    ...templatesStore.value,
                    currentTemplateID: data.templateID,
                  };
                  setCurrentTemplate({templateID: data.templateID, templateName: data.templateName, shortID: data.shortID});
                  navigate("/template-editor");
                  toast({
                    status: "success",
                    description: "new template created",
                  });
                },
                onError(err) {
                  toast({
                    status: "error",
                    description: err,
                  });
                },
              });
            }
          }}
        />
      </Flex>
    </Flex>
  );
};

export default CreateTemplateModal;

type TemplateChoiceButtonParams = {
  choice: string;
  choiceAction: () => void;
  choiceActive: boolean;
  colorMode: ColorMode;
  Icon: JSX.Element;
};
const TemplateChoiceButton = ({
  choice,
  choiceAction,
  colorMode,
  choiceActive,
  Icon,
}: TemplateChoiceButtonParams) => {
  return (
    <Flex
      flexDir={"column"}
      gap="8px"
      alignItems={"center"}
      justifyContent="center"
      cursor={"pointer"}
      onClick={choiceAction}
    >
      <Flex
        w="54px"
        h="54px"
        borderRadius="8px"
        alignItems={"center"}
        justifyContent="center"
        bgColor={colorMode === "light" ? lightIconBg : darkIconBg}
        border={
          choiceActive
            ? "2px solid " +
              (colorMode === "light" ? "black" : "white")
            : "none"
        }
      >
        {Icon}
      </Flex>
      <Text fontSize={"0.875rem"} fontWeight="500">
        {choice}
      </Text>
    </Flex>
  );
};
