import {
  Flex,
  Text,
  useToast,
  Select,
} from '@chakra-ui/react'
import { useState, type FC } from 'react'

import Button from 'components/Button'
import { AdminRole, prettyAdminRole } from 'types/Admin'
import { PlainInput } from 'components/Input'
import { createAdmin } from 'apis/admin/create.admin'
import { createOperator } from 'apis/admin/create.operator'
import { createSuperOperator } from 'apis/admin/create.super.operator'
import { adminStore } from 'utils/store/admins'


interface ICreateAdminModal {
  // username: string
  onClose: () => void;
  // maxWidth: any
}

const CreateAdminModal: FC<ICreateAdminModal> = ({onClose}) => {
  const toast = useToast({
    position: 'top',
    containerStyle: {
      zIndex: 9,
    },
  })

  const [role, setAdminRole] = useState<AdminRole>(null as any);
  const [ adminData, setAdminData ] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });

  const handleFirstnameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    if (e.target.value.length <= 20) {
      setAdminData({
        ...adminData,
        firstname: e.target.value
          .replace(/\s+/g, '')
          .replace(/[^a-zA-Z0-9]/g,''),
      });
    }
  };

  const handleLastnameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    if (e.target.value.length <= 20) {
      setAdminData({
        ...adminData,
        lastname: e.target.value
          .replace(/\s+/g, '')
          .replace(/[^a-zA-Z0-9]/g,''),
      });
    }
  };

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    setAdminData({
      ...adminData,
      email: e.target.value
        .toLowerCase()
        .replace(/\s+/g, ''),
    });
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    if (e.target.value.length <= 20) {
      setAdminData({
        ...adminData,
        password: e.target.value,
      });
    }
  };


  return (
    <Flex
      flexDir={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      fontFamily={'DM Sans, sans-serif'}
      pt={'0.5rem'}
      pb={'1.3rem'}
      // maxW={{base:'26.75rem', lg:'30rem'}}
      // maxH={{ base: '20.375rem', md: '21.25rem' }}
      overflowY={"auto"}
    >
      <Text fontSize="1rem" fontWeight={700} mb={'2rem'}>
        Create a new admin
      </Text>
      <Select 
        placeholder='Select role' 
        onChange={(e) => {
          setAdminRole(e.target.value as AdminRole)
        }}
      >
        <option value={AdminRole.OPERATOR}>operator</option>
        <option value={AdminRole.SUPER_OPERATOR}>super operator</option>
        <option value={AdminRole.ADMIN}>admin</option>
        {/* <option value={AdminRole.SUPPER_ADMIN}>super admin</option> */}
      </Select>
      <Flex
        w={'100%'}
        flexDir={'column'}
        mt={'1.88rem'}
        gap={'0.62rem'}
        justifyContent={'center'}
        alignItems={"center"}
      >

        <PlainInput 
          name={"first_name"}
          placeholder='firstname'
          value={adminData.firstname}
          onChange={handleFirstnameChange}
        />
        <PlainInput 
          name={"last_name"}
          placeholder='lastname'
          value={adminData.lastname}
          onChange={handleLastnameChange}
        />
        <PlainInput 
          name={"email"}
          placeholder='email'
          value={adminData.email}
          onChange={handleEmailChange}
        />
        <PlainInput 
          name={"password"}
          placeholder='password'
          value={adminData.password}
          onChange={handlePasswordChange}
        />

        <Button
          text={"create"}
          isDisabled={
            !adminData.firstname && !adminData.lastname && !adminData.email && !adminData.password
          }
          onClick={() => {
            const createAdminFn = role === AdminRole.ADMIN ?
              createAdmin :
              role === AdminRole.SUPER_OPERATOR ? 
              createSuperOperator :
              createOperator;
            
            createAdminFn(
              {
                body: {
                  firstname: adminData.firstname,
                  lastname: adminData.lastname,
                  email: adminData.email,
                  password: adminData.password,
                },
                onCompleted(data) {
                  adminStore.value = {
                    ...adminStore.value,
                    admins: data,
                  };
                  toast({
                    status: 'success',
                    description: "new " + prettyAdminRole[role] + " created",
                  })
                  onClose();
                },
                onError(err) {
                  toast({
                    status: 'error',
                    description: "could not create " + prettyAdminRole[role],
                  });
                  onClose();
                },
              }
            );
          }}
        />
      </Flex>
    </Flex>
  )
}

export default CreateAdminModal
