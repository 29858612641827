import { generateAPI } from "apis/api.generator";


export const deleteTemplate = generateAPI<
  {
    body: {
      templateID: string;
    };
  },
  {
    templateID: string;
    templateName: string;
    description: string;
    isInApp: boolean;
    coverImage: string;
    shortID: string;
  }[]
>("post", "template/delete");
