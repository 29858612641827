import { ChakraProvider, theme } from "@chakra-ui/react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Routes from "routes";
import { Global } from "@emotion/react";
import { GlobalStyles } from "theme/GlobalStyles";
import { type FC } from "react"

export const App: FC = () => {
  const ErrorFallback: FC = () => {
    return <p>Sorry, there is an error!</p>;
  };
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ChakraProvider theme={theme}>
        <Global styles={GlobalStyles} />
        <Router>
          <Routes />
        </Router>
      </ChakraProvider>
    </ErrorBoundary>
  );
};