

export const removeEmpty = (data: Record<string, any>): Record<string, any> =>
  Object.fromEntries(
    Object.entries(data).filter(([, value]) => value.length > 0)
  );

// export const removeEmptyArray = (data: SocialAppTypeResponse[]) =>
//   data.filter((arr) => arr.handle.length > 0);

// export const solvePayload = (data: SectionData[]) => {
//   const payloadData = data.map((p) => ({
//     sectionID: p._id,
//     sectionKind: p.kind,
//   }));
//   return payloadData;
// };

export const capitaliseWord = (word: string): string =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const capitaliseString = (str: string): string =>
  str.split(" ").map(capitaliseWord).join(" ");

export const removeUnderScore = (str: string): string =>
  str.split("_").map(capitaliseString).join(" ");
