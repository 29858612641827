import { generateAPI } from "apis/api.generator";


export const permanentlyDeleteTemplate = generateAPI<
  {
    body: {
      templateID: string,
    };
  },
  {
    deleted: {templateID: string, templateName: string, shortID: string, description:string, isInApp: boolean, coverImage: string, daysLeft: number}[],
    active: {templateID: string, templateName: string, shortID: string,  description:string, isInApp: boolean, coverImage: string, daysLeft: number}[],
  }
>("post", "template/admin/permanently-delete");
