import { useContext, type FC } from 'react'
import { Sidebar } from './parts'
import { Body } from './composition'
import { Box, useColorMode, useMediaQuery } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import theme from "theme"
import { CurrentUserContext } from 'routes/AuthRoutesProvider'

const Dashboard: FC = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const navigate = useNavigate()
  const openRoute = (route: string): void => {
    navigate(route)
  }

  const { pathname } = useLocation()
  const { colorMode } = useColorMode()

  const isEditor = pathname === '/template-editor'

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  const darkBg = theme.colors.background.primary._dark;
  const lightBg = theme.colors.background.primary.default;

  // const { profile } = currentUser;

  return (
    <Box
      className={'no-scrollbar'}
      w={'full'}
      h={'100dvh'}
      display={{ base: 'flex', md: 'grid' }}
      overflow={'auto'}
      pos={'relative'}
      alignItems={'flex-start'}
      gridTemplateColumns={'4.5rem minmax(0px, 1fr)'}
      bg={
        colorMode === 'light' ? lightBg : darkBg
      }
    >
      <Sidebar
        avatar={currentUser.avatar}
        colorMode={colorMode}
        isEditor={isEditor}
        openRoute={openRoute}
        username={currentUser.firstname}
        isWeb={isLargerThan768}
      />
      <Body
        colorMode={colorMode}
        isEditor={isEditor}
        isWeb={isLargerThan768}
        openRoute={openRoute}
        username={currentUser.firstname}
      />
    </Box>
  )
}
export default Dashboard
