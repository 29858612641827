import { generateAPI } from "apis/api.generator";
import { Template } from "types/Template";


export const createTemplateFromPeige = generateAPI<
  {
    body: {
      isInApp: boolean;
      templateName: string;
      peigeUsername: string;
      description: string;
      coverImage: string;
    };
  },
  Template
>("post", "template/peige");
