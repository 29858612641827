import { type IIcons } from "./icon.types";


export const TemplatesIcon = ({mode, activeStatus}: IIcons) => {
  const color = {
    light: {
      active: "#040404",
      inactive: "#808080",
    },
    dark: {
      active: "#F5CE6F",
      inactive: "#F5F5F5",
    }
  }[mode][activeStatus];

  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M12.4569 1.3767L14.9043 1.79937C16.044 1.99619 16.7268 3.14604 16.3339 4.20686L12.0817 15.6874L12.4569 1.3767Z" fill={color}/>
    <path d="M16.7931 5.91285L18.6697 6.46197C19.9734 6.84344 20.4327 8.41277 19.5316 9.40691L12.1666 17.5325L16.7931 5.91285Z" fill={color}/>
    <path d="M0 1.81818C0 0.814027 0.835424 0 1.86597 0H9.32986C10.3604 0 11.1958 0.814028 11.1958 1.81818V18.1818C11.1958 19.186
     10.3604 20 9.32986 20H1.86597C0.835425 20 0 19.186 0 18.1818V1.81818Z" fill={color}/>
  </svg>
  );
};
