import { generateAPI } from "apis/api.generator";
import { Admin } from "types/Admin";

export const login = generateAPI<
  {
    body: {
      email: string;
      password: string;
    }
  }, 
  Admin
  >("post", "auth/signin-admin");
