import { Flex, Image, useColorMode } from '@chakra-ui/react'
import { type FC } from 'react'

interface ISearchCategory {
  onClick: (category: string) => void
}

const SearchCategory: FC<ISearchCategory> = ({ onClick }: ISearchCategory) => {
  const categoryList = [
    {
      action: 'books',
      image: 'svgs/ph_books.svg',
      text: 'Books',
    },
    {
      action: 'woman',
      image: 'svgs/woman.svg',
      text: 'Woman',
    },
    {
      action: 'food',
      image: 'svgs/food.svg',
      text: 'Food',
    },
    {
      action: 'house',
      image: 'svgs/house.svg',
      text: 'House',
    },
    {
      action: 'animal',
      image: 'svgs/animals.svg',
      text: 'Animals',
    },
    {
      action: 'car',
      image: 'svgs/car.svg',
      text: 'Car',
    },
    {
      action: 'doll',
      image: 'svgs/teddy-bear.svg',
      text: 'Doll',
    },
    {
      action: 'art',
      image: 'svgs/art.svg',
      text: 'Art',
    },
    {
      action: 'flower',
      image: 'svgs/flower.svg',
      text: 'Flower',
    },
    {
      action: 'emoji',
      image: 'svgs/emoji.svg',
      text: 'Emoji',
    },
  ]

  const { colorMode } = useColorMode()
  return (
    <Flex
      gap={16}
      justifyContent="center"
      flexWrap="wrap"
      align="center"
      paddingTop={5}
    >
      {categoryList.map((category, index) => {
        const { action, image } = category
        return (
          <center
            key={index}
            onClick={() => {
              onClick(action)
            }}
          >
            <Image
              src={image}
              alt={action}
              width={'40px'}
              height={'40px'}
              filter={colorMode === 'light' ? 'invert(1)' : ''}
            />
          </center>
        )
      })}
    </Flex>
  )
}

export default SearchCategory
