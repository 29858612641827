import {
  Flex,
  Text,
  useToast,
  Select,
} from '@chakra-ui/react'
import { useState, type FC } from 'react'

import Button from 'components/Button'
import { AdminRole } from 'types/Admin'
import { adminStore } from 'utils/store/admins'
import { changeAdminRole } from 'apis/admin/change.admin.role'


interface IChangeAdminRoleModal {
  // username: string
  adminRole: AdminRole;
  adminID: string;
  onClose: () => void;
  // maxWidth: any
}

const ChangeAdminRoleModal: FC<IChangeAdminRoleModal> = ({adminRole, adminID, onClose}) => {
  const toast = useToast({
    position: 'top',
    containerStyle: {
      zIndex: 9,
    },
  })

  const [role, setAdminRole] = useState<AdminRole>(adminRole);


  return (
    <Flex
      flexDir={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      fontFamily={'DM Sans, sans-serif'}
      pt={'0.5rem'}
      pb={'1.3rem'}
      // maxW={{base:'26.75rem', lg:'30rem'}}
      // maxH={{ base: '20.375rem', md: '21.25rem' }}
      overflowY={"auto"}
    >
      <Text fontSize="1rem" fontWeight={700} mb={'2rem'}>
        Change admin role
      </Text>
      <Select 
        placeholder='Select role' 
        value={role}
        onChange={(e) => {
          setAdminRole(e.target.value as AdminRole)
        }}
      >
        <option value={AdminRole.OPERATOR}>operator</option>
        <option value={AdminRole.SUPER_OPERATOR}>super operator</option>
        <option value={AdminRole.ADMIN}>admin</option>
        {/* <option value={AdminRole.SUPPER_ADMIN}>super admin</option> */}
      </Select>
      <Flex
        w={'100%'}
        flexDir={'column'}
        mt={'1.88rem'}
        gap={'0.62rem'}
        justifyContent={'center'}
        alignItems={"center"}
      >
        <Button
          text={"change"}
          isDisabled={false}
          onClick={() => {
            changeAdminRole(
              {
                body: {
                  role,
                  adminID,
                },
                onCompleted(data) {
                  adminStore.value = {
                    ...adminStore.value,
                    admins: data,
                  };
                  toast({
                    status: 'success',
                    description: "admin role changed",
                  })
                  onClose();
                },
                onError(err) {
                  toast({
                    status: 'error',
                    description: "could not change role",
                  });
                  onClose();
                },
              }
            );
          }}
        />
      </Flex>
    </Flex>
  )
}

export default ChangeAdminRoleModal
