import Cookies from 'js-cookie'

export const logout = async (): Promise<void> => {
  localStorage.clear();
  sessionStorage.removeItem('token');
  Cookies.remove('token', { path: '/', domain: `${process.env.REACT_APP_DOMAIN}` })
  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
  if (isDarkMode) localStorage.setItem('chakra-ui-color-mode', 'dark')
  window.location.href = '/login'
}
