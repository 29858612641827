import { generateAPI } from "apis/api.generator";
import { Template } from "types/Template";

export const createDefaultTemplate = generateAPI<
  {
    body: {
      templateName: string;
      isInApp: boolean;
      coverImage: string;
      description: string;
    };
  },
  Template
>("post", "template/default");
