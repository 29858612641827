import { Menu } from "Lib";
import { type FC } from "react";
import { Flex } from "@chakra-ui/react";
import { type NavInterface } from "./types";
import { NavIcon, NavIconButton } from "components";
import { AdminPermissionMatrixStore } from "utils/admin.permission";


const Nav: FC<NavInterface> = ({ openRoute, order }: NavInterface) => {
  const p_bo_can_view_templates = AdminPermissionMatrixStore.value.p_bo_can_view_templates;

  return (
    <Flex
      flexDir={order === "column" ? "column" : "row"}
      justify="space-between"
      align={"center"}
      p={
        order === "column"
          ? { base: "2px 0", sm: "10px 0", md: "12px 0" }
          : { base: "0 2px", sm: "0 10px", md: "0 12px" }
      }
      pos={"relative"}
      gap={order === "column" ? 8 : 0}
    >
      {
        Menu
          .LinkItems
          .filter((nav) => {
            if (nav.name === "Templates") {
              if (!p_bo_can_view_templates) return false;
            }
            return true;
          })
          .map((link) => (
        <NavIconButton
          key={link.name}
          active={link.active}
          flexDir={"column"}
          open={() => { openRoute(link.route); }}
          icon={<NavIcon navname={link.name} />}
          iconLabel={link.name}
        />
      ))}
    </Flex>
  );
};

export default Nav;
