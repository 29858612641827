import { Box, Flex, Text, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { PlainInput } from 'components/Input'
import { type FC, useState } from 'react'

import { Grid } from '@giphy/react-components'
import { GiphyFetch, type GifsResult } from '@giphy/js-fetch-api'
import { type IGif } from '@giphy/js-types'
import SearchCategory from 'components/SearchCategory'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import EnvConfig from 'config/env.config'

const giphy = new GiphyFetch(EnvConfig.giphyAccessKey)

interface IGiphyModal {
  selectGiphyImage: (imageUrl: IGif) => void
  maxWidth: number
  onClose: () => void
}

const GiphyModal: FC<IGiphyModal> = ({ selectGiphyImage, maxWidth, onClose }: IGiphyModal) => {
  const { colorMode } = useColorMode()

  const [query, setQuery] = useState('')
  const [categoryOpen, setCategoryOpen] = useState(true)

  const fetchGifs = async (offset: number): Promise<GifsResult> => {
    return await giphy.search(query, { offset, limit: 10 })
  }

  return (
    <Box>
      <Flex>
        <Box
          onClick={onClose}
          borderRadius="50%"
          bgColor="rgba(194, 193, 193, 0.3)"
          width="28px"
          height="28px"
          display="flex"
          justifyContent={'center'}
          alignItems="center"
          cursor={'pointer'}
        >
          <ChevronLeftIcon
            color={colorMode === 'light' ? 'black' : 'white'}
            w={6}
            h={6}
          />
        </Box>
        <Text
          pb="10px"
          fontSize={'20px'}
          textAlign={'center'}
          color={useColorModeValue('black', 'white')}
          maxW={'44rem'}
          w={'90%'}
        >
          Giphy
        </Text>
      </Flex>

      <Box>
        <PlainInput
          placeholder="Search for an image"
          name={'giphy-image'}
          value={query}
          onChange={e => {
            setQuery(e.target.value)
          }}
          showMessage={false}
        />
        {(categoryOpen || !query) && <SearchCategory onClick={setQuery} />}
      </Box>

      <Flex
        pos={'relative'}
        justifyContent={'center'}
        flexWrap="wrap"
        gap="3px"
        pt="1rem"
      >
        <Grid
          noLink={true}
          onGifClick={selectGiphyImage}
          columns={2}
          width={maxWidth}
          gutter={6}
          fetchGifs={fetchGifs}
          key={query}
          onGifsFetched={() => {
            setCategoryOpen(false)
          }}
        />
      </Flex>
    </Box>
  )
}

export default GiphyModal
