import { Flex, Image, Text } from "@chakra-ui/react";
import { type FC } from "react";
import theme from 'theme';
import { type ISocialIcon } from "typings/interface";

const lightIconBg = theme.colors.shareModal.light.iconBg
const darkIconBg = theme.colors.shareModal.dark.iconBg


  const SocialIcon: FC<ISocialIcon> = ({ bgColor, colorMode, icon, name, shareAction }: ISocialIcon) => {

    return (
      <Flex
        flexDir={'column'}
        gap="8px"
        alignItems={'center'}
        justifyContent="center"
        cursor={'pointer'}
        onClick={shareAction}
      >
        <Flex
          w="54px"
          h="54px"
          borderRadius="8px"
          alignItems={'center'}
          justifyContent="center"
          bgColor={bgColor ?? colorMode === 'light' ? lightIconBg : darkIconBg}
        >
          <Image
            src={icon}
            w={'28px'}
            h={'28px'}
            alt={name}
            filter={colorMode === 'light' ? 'invert(1)' : ''}
            loading={'lazy'}
          />
        </Flex>
        <Text fontSize={'0.875rem'} fontWeight="500">
          {name}
        </Text>
      </Flex>
    )
  };

export default SocialIcon;
