import { generateAPI } from "apis/api.generator";
import { Individual } from "types/Individual";

export const getProfile = generateAPI<
{
  query: {
    individualID: string;
  };
},
Individual
>("get", "individual/admin");
