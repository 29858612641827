import ReactModal from 'react-modal'
import {
  Box,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react'
import { SmallCloseIcon } from '@chakra-ui/icons'
import './Modal.css'
import { type IModal } from 'typings/interface'
import { type FC } from 'react'
import theme from 'theme'

ReactModal.setAppElement('#root')

ReactModal.defaultStyles.content = {
  backgroundColor: `${theme.colors.default.black}`,
  width: '100%',
  margin: '1rem',
  overflowY: 'auto',
}

const Modal: FC<IModal> = ({
  isOpen,
  onAfterOpen,
  onAfterClose,
  onRequestClose,
  contentLabel,
  id,
  shouldFocusAfterRender,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc,
  shouldReturnFocusAfterClose,
  preventScroll,
  contentStyles,
  showCloseIcon,
  children,
  position,
  width,
  // full,
  fullHeight,
  // fullWidth,
  bottomSheet,
  bg,
}: IModal) => {
  const { colorMode } = useColorMode()
  const themeBlack = theme.colors.default.black
  const themeWhite = theme.colors.default.white

  const [isLargerThan992] = useMediaQuery('(min-width: 992px)')
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      id={id}
      shouldFocusAfterRender={shouldFocusAfterRender}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      preventScroll={preventScroll}
      ariaHideApp={false}
      style={{
        content: {
          [position as string]: 0,
          minHeight: fullHeight && '100vh',
          height: (fullHeight && '100vh') ?? (bottomSheet && '80vh'),
          margin: '0px',
          marginLeft: position === 'right' && 'auto',
          marginright: position === 'left' && 'auto',
          background: bg ?? (colorMode === 'light' ? themeWhite : themeBlack),
          borderColor: useColorModeValue('white', 'black'),
          maxWidth: isLargerThan992
            ? '45.6rem'
            : isLargerThan768
            ? '39.5rem'
            : '32.75rem',
          overflow: 'hidden',
          // paddingBottom: '2.3rem',
          ...contentStyles,
          // paddingLeft: isLargerThan992 ? '12.7rem' : '1.75rem',
          // paddingRight: isLargerThan992 ? '12.7rem' : '1.75rem'
        },
        overlay: { zIndex: 1000 },
      }}
    >
      {showCloseIcon && (
        <Box
          textAlign="right"
          padding="0.5rem"
          display="flex"
          justifyContent="flex-end"
        >
          <Box
            onClick={onRequestClose}
            borderRadius="50%"
            bgColor="rgba(194, 193, 193, 0.3)"
            width="30px"
            height="30px"
            display="flex"
            justifyContent={'center'}
            alignItems="center"
            cursor={'pointer'}
          >
            <SmallCloseIcon
              color={colorMode === 'light' ? 'black' : 'white'}
              w={6}
              h={6}
              id="test"
            />
          </Box>
        </Box>
      )}
      {children}
    </ReactModal>
  )
}

export default Modal
