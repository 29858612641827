import { useState, type FC, useEffect } from "react";
import InfoCard from "components/InfoCard";
import { Button, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { getDashboard } from "apis/dashboard/get.dashboard";
import { logout } from "utils/logout";
import { AdminPermissionMatrixStore } from "utils/admin.permission";

const sampleData = {
  total_users: 0,
  total_published_peiges: 0,
  total_peige_views: 0,
  total_link_clicks: 0,
  total_early_access: 0,
};

const stripTitle = (title: string) => {
  return title.split("_").slice(1).join(" ");
};

const formatValue = (value: number) => {
  const language = "en";
  return Intl.NumberFormat(language, { notation: "compact" }).format(
    value
  );
};

const Overview: FC = () => {
  const p_bo_can_view_dashboard_metrics = AdminPermissionMatrixStore.value.p_bo_can_view_dashboard_metrics;
  const p_bo_can_view_users_count = AdminPermissionMatrixStore.value.p_bo_can_view_users_count;
  const p_bo_can_view_users_list = AdminPermissionMatrixStore.value.p_bo_can_view_users_list;
  const p_bo_can_view_feedback = AdminPermissionMatrixStore.value.p_bo_can_view_feedback;
  const p_bo_can_view_templates = AdminPermissionMatrixStore.value.p_bo_can_view_templates;
  const p_bo_can_create_admin = AdminPermissionMatrixStore.value.p_bo_can_create_admin;

  const [dashboardData, setDashboardData] = useState(sampleData);
  useEffect(() => {
    if (p_bo_can_view_dashboard_metrics) {
      getDashboard({
        onCompleted: (_dashboardData) => {
          setDashboardData(_dashboardData.overview);
        },
        onError: () => {
          //
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();
  return (
    <Flex flexDir={"column"}>
      <Flex alignSelf={"end"} pr={"1rem"}>
        <Button onClick={logout} border={"1px solid black"}>
          Logout
        </Button>
      </Flex>
      {
        p_bo_can_view_dashboard_metrics ?

        <Flex wrap={"wrap"} gap={"0.5rem"} p={"1rem"}>
          {Object.entries(dashboardData).filter(
            ([key, ]) => {
              if (key === "total_users" && !p_bo_can_view_users_count) {
                return false;
              }
              return true;
            })
            .map(([key, value], index) => (
            <InfoCard
              key={index}
              title={stripTitle(key)}
              value={formatValue(value)}
              cursor={key === "total_users" ? "pointer" : ""}
              {...(key === "total_users" && p_bo_can_view_users_list
                ? { cardClick: () => navigate("/users") }
                : {})}
            />
          ))}
        </Flex> :

        <></>

      }

     <Flex gap={"0.5rem"}>
       {
        p_bo_can_view_templates ?

        <Flex pl={"1rem"}>
          <Button
            onClick={() => navigate("/templates")}
            border={"1px solid black"}
          >
            Templates
          </Button>
        </Flex> :
        <></>
       }
       {
        p_bo_can_view_feedback ?
        <Flex pl={"1rem"}>
          <Button
            onClick={() => navigate('/feedback')}
            border={"1px solid black"}
          >
            Feedback
          </Button>
        </Flex> :
        <></>
       }
      <Flex pl={"1rem"}>
        <Button
          onClick={() => navigate('/contacts')}
          border={"1px solid black"}
        >
          Contacts
        </Button>
      </Flex>

      {
        p_bo_can_create_admin ? 
          <Flex pl={"1rem"}>
            <Button
              onClick={() => navigate('/admins')}
              border={"1px solid black"}
            >
              Admins
            </Button>
          </Flex> :
          <></>
      }
      </Flex>
    </Flex>
  );
};

export default Overview;
