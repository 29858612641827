import { 
  Box, 
  Button, 
  Input, 
  Text,
  useToast,
} from "@chakra-ui/react";
import { login } from "apis/auth/login";
import { useState, type FC } from "react";
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from "routes/links";
import { AdminPermissionMatrix, AdminPermissionMatrixStore } from "utils/admin.permission";

const LoginForm: FC = () => {
  const navigate = useNavigate();
  const toast = useToast({
    position: 'top',
    containerStyle: {
      zIndex: 9,
    },
  });
  const [auth, setAuth] = useState({
    email: "",
    password: "",
  });

  const [showPwd, setShowPwd] = useState(false);

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = e => {
  e.preventDefault()
    setAuth({
      ...auth,
      email: e.target.value,
    })
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    e.preventDefault()
      setAuth({
        ...auth,
        password: e.target.value,
      })
    };
  
  const handleSubmit = () => {
    login({
      body: {
        email: auth.email,
        password: auth.password,
      },
      onCompleted: (data, token) => {
        const { ...defaultPermissions } = AdminPermissionMatrix;
        data.permissions.forEach((permission) => {
          defaultPermissions[permission] = true;
        });
        AdminPermissionMatrixStore.value = defaultPermissions;
        Cookies.set('token', token, { secure: true })
        sessionStorage.setItem('token', token)
        navigate(ROUTES.dashboard)
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'email or password incorrect',
        })
      },
    })
  };

  return (
    <Box w={{ base: "250px", md: "320px"}}>
      <form>
        <Text
          color={"white"}
        >Email</Text>
        <Input 
          placeholder="email"
          type="text" 
          bg={'white'} 
          color={'black'}
          value={auth.email}
          onChange={handleEmailChange}
          mb={"10px"}
        />
        <Text
          color={"white"}
        >Password</Text>
        <Input 
          placeholder="password" 
          type={showPwd ? "text" : "password"} 
          bg={'white'} 
          color={'black'}
          value={auth.password}
          onChange={handlePasswordChange}
        />
        <Text
          color={"white"}
          cursor={"pointer"}
          textDecoration={"underline"}
          onClick={() => {
            setShowPwd(!showPwd)
          }}
          mb={"5px"}
        >{showPwd ? "hide" : "show"} Password</Text>
        <Button 
          // type="submit" 
          bgColor={"wheat"}
          w={"full"}
          onClick={handleSubmit} 
          color={'black'}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default LoginForm;
