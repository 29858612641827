import Cookies from 'js-cookie'
import { createContext, useEffect, useState } from 'react'
import { Admin, AdminRole } from 'types/Admin'
import { ROUTES } from './links'
import { useNavigate } from 'react-router-dom'
import { useAdmin } from 'hooks/useAdmin'
import { AppState } from './app.state'


export const CurrentUserContext = createContext<{ 
  currentUser: Admin, 
  appState: AppState,
  setAppState: (appState: AppState) => void,
}>({
  currentUser: {} as any,
  appState: {
    viewContext: 'Editor',
    changeState: 'clean',
    openShareDrawer: false,
    openLinkDrawer: false,
  },
  setAppState(appState) {},
})

interface AuthRouteProviderProps {
  children: React.ReactNode
}

const AuthRouteProvider = ({ children }: AuthRouteProviderProps) => {
  const navigate = useNavigate()
  // TODO: useEffect hook to check for the cookie on mount
  const [appState, setAppState] = useState<AppState>({
    viewContext: 'Editor',
    changeState: 'clean',
    openShareDrawer: false,
    openLinkDrawer: false,
  })

  useEffect(() => {
    const loggedInUserToken =
      Cookies.get('token') ?? sessionStorage.getItem('token')
    if (!loggedInUserToken) {
      navigate(ROUTES.login)
    }
  }, [navigate])

  const { admin, error } = useAdmin();

  if (error) {
    // TODO: navigate to login
    tokenExpiredSync()
    navigate(ROUTES.login)
  }

  const currentUser = admin || {
    firstname: "dummy",
    lastname: "dummy",
    username: "dummy",
    phone: "09066744819",
    email: "dummy",
    password: "dummy",
    DOB: new Date(),
    avatar: "dummy",
    role: AdminRole.SUPPER_ADMIN,
    permissions: [],
  };

  return (
    <CurrentUserContext.Provider value={{ currentUser, appState, setAppState }}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export default AuthRouteProvider

export const tokenExpiredSync = () => {
  sessionStorage.removeItem('token');
  Cookies.remove('token', { path: '/', domain: `${process.env.REACT_APP_DOMAIN}` })
}
