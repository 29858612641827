// import { useMutation } from '@apollo/client'
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useColorModeValue,
  // useToast,
} from '@chakra-ui/react'
// import { SEND_VERIFICATION_LINK_MUTATION } from 'apolloClient/mutations/sendVerificationLink'
import { MenuItemCmp } from 'components'
import { useContext, type FC } from 'react'
import { CurrentUserContext } from 'routes/AuthRoutesProvider'
import { type IUserAvatar } from 'typings/interface'

const UserAvatar: FC<IUserAvatar> = ({
  avatar,
  goto,
  logOut,
}: IUserAvatar) => {
  const { currentUser } = useContext(CurrentUserContext)
  // const { profile } = currentUser;

  // const [verifyIndividualEmail] = useMutation(SEND_VERIFICATION_LINK_MUTATION)

  // const toast = useToast({
  //   position: 'top',
  //   containerStyle: {
  //     zIndex: 9,
  //   },
  // })

  // const handleSendVerification = (): void => {
  //   verifyIndividualEmail({
  //     variables: {},
  //     onCompleted: data => {
  //       toast({
  //         status: 'success',
  //         description: data.sendVerifyLink.message,
  //       })
  //     },
  //     onError: error => {
  //       toast({
  //         status: 'error',
  //         description: error.message,
  //       })
  //     },
  //   })
  // }


  return (
    <HStack spacing={{ base: '0', lg: '6' }}>
      <Flex alignItems={'center'}>
        <Menu strategy={'fixed'}>
          <MenuButton
            py={2}
            transition="all 0.3s"
            _focus={{ boxShadow: 'none' }}
          >
            <Box
              border={'2px solid'}
              borderColor={'transparent'}
              borderRadius={'50%'}
            >
              <Avatar
                style={{
                  border: '1px solid',
                  borderColor: useColorModeValue('white', 'black'),
                }}
                size={'sm'}
                w={{ base: '25px', md: '32px' }}
                h={{ base: '25px', md: '32px' }}
                src={avatar ?? '/avatar.webp'}
              />
            </Box>
          </MenuButton>
          <MenuList
            bg={useColorModeValue('#F1F1F1', '#111111')}
            borderColor={useColorModeValue(
              'rgba(237, 237, 237, 0.5)',
              'rgba(34, 36, 44, 0.5)',
            )}
            zIndex="100"
          >
            <MenuItemCmp
              onClick={() => goto?.('/settings')}
              name={
                <Flex mb={'10px'} onClick={() => {console.log('Hey user')}}>
                  <Box
                    border={'2px solid'}
                    borderColor={'transparent'}
                    borderRadius={'50%'}
                  >
                    <Avatar
                      style={{
                        border: '1px solid',
                        borderColor: useColorModeValue('white', 'black'),
                      }}
                      size={'sm'}
                      w={'35px'}
                      h={'35px'}
                      src={avatar ?? '/avatar.webp'}
                    />
                  </Box>
                  {/* TODO: Change this to a button and link to settings/profile */}
                  <Flex flexDir={'column'} ml={'10px'} justify={'center'}>
                    <Text
                      fontSize={'14px'}
                      fontWeight={'500'}
                      lineHeight={'18.23px'}
                    >
                      @{currentUser.firstname}
                    </Text>
                  </Flex>
                </Flex>
              }
            />
            <MenuItemCmp
              onClick={() => goto?.('/settings')}
              name={'Settings'}
            />
            <MenuItemCmp onPromiseClick={logOut} name={'Sign out'} />
          </MenuList>
        </Menu>
      </Flex>
    </HStack>
  )
}

export default UserAvatar
