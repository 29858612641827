import {
  Flex,
  useMediaQuery,
  useColorMode,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Box,
  DrawerCloseButton,
} from '@chakra-ui/react'
import Modal from 'components/Modal'
import { type FC } from 'react'
import theme from 'theme'

interface IResponsiveModal {
  isOpen: boolean
  toggle: () => void
  showCloseButton?: boolean
  content: React.ReactNode
  maxWidth?: string
  width?: string
  minH?: string
  maxH?: string
  padding?: string
  modalType?: string
  isFull?: boolean
  bg?: string
  preventScroll?: boolean
  contentStyles?: any
}

const ResponsiveModal: FC<IResponsiveModal> = ({
  isOpen,
  toggle,
  content,
  showCloseButton,
  maxWidth,
  width,
  modalType,
  padding,
  minH,
  maxH,
  isFull,
  bg,
  contentStyles,
  preventScroll,
}: IResponsiveModal) => {
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)')
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  const themeBlack = theme.colors.default.black
  const themeWhite = theme.colors.default.white

  const { colorMode } = useColorMode()

  const maxWidthNow = (maxWidthValue: string) => {
    if (maxWidthValue) return maxWidthValue
    return isLargerThan768 ? '560px' : '400px'
  }

  return (
    <Flex>
      <Modal
        isOpen={isOpen && isLargerThan600}
        onRequestClose={toggle}
        showCloseIcon={false}
        preventScroll={preventScroll}
        contentStyles={{
          maxWidth: maxWidthNow(maxWidth as string),
          border: 'none',
          borderRadius: isLargerThan600 ? '25px' : '25px 25px 0 0',
        }}
        shouldFocusAfterRender={false}
        contentLabel={modalType}
        id={modalType}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        bg={bg}
      >
        <Box
          display="flex"
          flexDir={'column'}
          p={padding ?? '0rem 2rem 2rem'}
          minH={minH}
          maxH={maxH ?? '95vh'}
          pos={'relative'}
          padding={padding ?? 6}
        >
          {content}
        </Box>
      </Modal>
      {
        !isLargerThan600 ?
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="bottom"
          onClose={toggle}
          returnFocusOnClose={false}
          // onOverlayClick={toggle}
          closeOnOverlayClick={true}
          blockScrollOnMount
          isFullHeight={isFull}
        >
          <DrawerOverlay background="rgba(0, 0, 0, 0.5)" />
          <DrawerContent
            bg={colorMode === 'light' ? themeWhite : themeBlack}
            roundedTop={'2xl'}
            position={'fixed'}
            zIndex={'tooltip'}
            maxH={'89vh'}
          >
            {showCloseButton && <DrawerCloseButton />}
            {
              <Box
                w={'full'}
                h={'full'}
                className="no-scrollbar"
                overflow={'auto'}
                p={{ base: padding ?? 4, md: 6 }}
              >
                {content}
              </Box>
            }
          </DrawerContent>
        </Drawer>
        :
        <></>
      }
    </Flex>
  )
}

export default ResponsiveModal
