import { generateAPI } from "apis/api.generator";
import { Admin, AdminRole } from "types/Admin";


export const changeAdminRole = generateAPI<
  {
    body: {
      role: AdminRole;
      adminID: string;
    }
  }, 
  Admin[]
>("put", "auth/change-admin-role");
