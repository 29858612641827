import { FC, useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom'
// import { DeleteIcon } from "@chakra-ui/icons";
// import { Button, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { Button, Flex, Image, Text, useToast } from "@chakra-ui/react";

import ResponsiveModal from "components/ResponsiveModal";
import ConfirmationModal from "components/AppModals/ConfirmationModal";
import { getDeletedTemplates } from "apis/templates/get.deleted.templates";
// import { permanentlyDeleteTemplate } from "apis/templates/permanently.delete.template";
import PermanentlyDeleteOrRestoreTemplateModal from "components/AppModals/PermanentlyDeleteOrRestoreTemplateModal";
import { templatesBinStore, templatesStore } from "utils/store/templates";
import { AdminPermissionMatrixStore } from "utils/admin.permission";
import { permanentlyDeleteAllTemplates } from "apis/templates/permanently.delete.all.templates";
// import { permanentlyDeleteAllTemplates } from "apis/templates/permanently.delete.all.templates";


const TemplatesBin = () => {
  const toast = useToast({
    position: 'top',
    containerStyle: {
      zIndex: 9,
    },
  })

  const p_bo_can_view_templates = AdminPermissionMatrixStore.value.p_bo_can_view_templates;

  const [permanentlyDeleteOrRestoreTemplateOpen, setPermanentlyDeleteOrRestoreTemplateOpen] = useState(false);
  const [openDeleteTemplate, setOpenDeleteTemplate] = useState(false);
  const [openDeleteAllTemplates, setOpenDeleteAllTemplates] = useState(false);

  const [permanentlyDeleteOrRestoreTemplateData, setPermanentlyDeleteOrRestoreTemplateData] = useState(
    {
      templateID: "",
      templateName: "",
      description: "",
      coverImage: "",
      isInApp: false,
    }
  );

  // const toast = useToast({
  //   position: 'top',
  //   containerStyle: {
  //     zIndex: 9,
  //   },
  // });

  const navigate = useNavigate()

  useEffect(() => {
    if (p_bo_can_view_templates) getDeletedTemplates({
      onCompleted: (_templatesData) => {
        templatesBinStore.value = {
          ...templatesBinStore.value,
          templates: _templatesData,
        };
      },
      onError: () => {
        //
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePermanentlyDeleteOrRestoreTemplate = () => {
    setOpenDeleteTemplate(false);
  };

  const handleDeleteAllTemplates = () => {
    permanentlyDeleteAllTemplates(
      {
        onCompleted(data) {
          templatesStore.value = {
            ...templatesStore.value,
            templates: data.active,
          }
          templatesBinStore.value = {
            ...templatesBinStore.value,
            templates: data.deleted,
          };
          setOpenDeleteAllTemplates(false);
          toast({
            status: 'success',
            description: "all templates deleted",
          });
        },
        onError(err) {
          toast({
            status: 'error',
            description: "could not delete templates",
          });
          setOpenDeleteAllTemplates(false);
        },
      },
    );
  };

  const toggleDeleteAllTemplatesModal = () => {
    setOpenDeleteAllTemplates(!openDeleteAllTemplates);
  };
  const togglePermanentlyDeleteOrRestoreTemplateModal = () => {
    setPermanentlyDeleteOrRestoreTemplateOpen(!permanentlyDeleteOrRestoreTemplateOpen);
  };

	return (
    <Flex
      flexDirection={"column"}
      gap={"15px"}
      w={"full"}
    >
      <Flex
        justifyContent={"space-between"}
      >
        <Button
          w={"max-content"}
          border={"1px solid black"}
          onClick={toggleDeleteAllTemplatesModal}
        >
          Empty
        </Button>
        <Text>
          Items in the bin are permanently deleted after 7 days
        </Text>
        <Button
          border={"1px solid black"}
          onClick={() => navigate('/templates')}
        >
          back to templates
        </Button>
      </Flex>
      <Flex
        gap={'1rem'}
        flexWrap={"wrap"}
        h={'85vh'}
        overflowY={'auto'}
        className={'no-scrollbar'}
        pb={"50px"}
        mr={'auto'}
      >
        {
          templatesBinStore.value.templates.map((template) => {
            const {templateID, templateName, description, isInApp, coverImage, daysLeft} = template;

            return (
              <Flex
                key={templateID}
                flexDirection={"column"}
              >
                <DeleteTemplateCard
                  key={templateID}
                  templateName={templateName}

                  coverImage={coverImage}
                  deleteOrRestoreTemplate={() => {
                    setPermanentlyDeleteOrRestoreTemplateData({
                      templateID, templateName, description, isInApp, coverImage
                    })
                    setPermanentlyDeleteOrRestoreTemplateOpen(true)
                  }}
                />
                <Text>{daysLeft} {daysLeft === 1 ? "day" : "days"} left</Text>
              </Flex>
            );
          })
        }
      </Flex>
      <ConfirmationModal
        text="Are you sure you want to permanently delete all templates?"
        subText="you cannot undo this action"
        openModal={openDeleteAllTemplates}
        toggleModal={() => {
          setOpenDeleteAllTemplates(false)
        }}
        handleRemoveImg={handleDeleteAllTemplates}
      />
      <ResponsiveModal
        isOpen={permanentlyDeleteOrRestoreTemplateOpen}
        toggle={togglePermanentlyDeleteOrRestoreTemplateModal}
        modalType="permanently delete template modal"
        maxWidth="30rem"
        content={<PermanentlyDeleteOrRestoreTemplateModal
          templateID={permanentlyDeleteOrRestoreTemplateData.templateID}
          onClose={togglePermanentlyDeleteOrRestoreTemplateModal}
        />}
      />
      <ConfirmationModal
        text="Are you sure you want to permanently delete this template?"
        subText="you cannot undo this action"
        openModal={openDeleteTemplate}
        toggleModal={() => {
          setOpenDeleteTemplate(false)
        }}
        handleRemoveImg={handlePermanentlyDeleteOrRestoreTemplate}
      />
    </Flex>
  );
}

const DeleteTemplateCard: FC<{
  templateName: string,
  coverImage: string,
  deleteOrRestoreTemplate: () => void,
}> = ({templateName, coverImage, deleteOrRestoreTemplate}) => {
  return (
    <Flex
      w={{base: "90px", md: "180px"}}
      h={{base: "195px", md: "390px"}}
      bgColor={"white"}
      borderRadius={"0.625rem"}
      flexDirection={"column"}
      position={"relative"}
    >
      <Image
        borderTopRadius={"0.625rem"}
        w={"full"}
        h={"85%"}
        cursor={"pointer"}
        onClick={deleteOrRestoreTemplate}
        objectFit={"cover"}
        src={coverImage}
      />
      <Flex
        borderTopRadius={"0.625rem"}
        // flexDirection={"column"}
        w={"full"}
        h={"15%"}
        bgColor={"white"}
        justifyContent={"space-between"}
        alignItems={"center"}
        fontSize={{base: "10px", md: "12px"}}
        px={{base:"5px", md: "20px"}}
      >
        <Text
          isTruncated
          color={"black"}
        >{templateName}</Text>
      </Flex>
    </Flex>
  );
}

export default TemplatesBin;
