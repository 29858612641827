import { getAdmin } from "apis/admin/get.admin";
import { getAllTemplates } from "apis/templates/get.all.templates";
import { useEffect, useState } from "react";
import { Admin } from "types/Admin";
import { templatesStore } from "utils/store/templates";
import { AdminPermissionMatrix, AdminPermissionMatrixStore } from "utils/admin.permission";


export const useAdmin = () => {
  const [error, setError] = useState(false); 
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState<Admin>(null as any);
  
  useEffect(() => {
    getAdmin({
      onCompleted: (data) => {
        setAdmin(data)
        setLoading(false);
        const { ...defaultPermissions } = AdminPermissionMatrix;
        data.permissions.forEach((permission) => {
          defaultPermissions[permission] = true;
        });
        AdminPermissionMatrixStore.value = defaultPermissions;
      },
      onError: () => {
        setError(true);
        setLoading(false);
      }
    });
    getAllTemplates({
      onCompleted: (_templatesData) => {
        // console.log(_templatesData);
        // setTemplatesData(_templatesData)
        templatesStore.value = {
          ...templatesStore.value,
          templates: _templatesData,
          ...(
            _templatesData?.[0] ?
            {currentTemplate: _templatesData[0]} :
            {}
          )
        };
      },
      onError: () => {
        //
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {admin, error, loading};
};
