import { generateAPI } from "apis/api.generator";


export const getAllTemplates = generateAPI<
  any,
  {
    templateID: string;
    templateName: string;
    description: string;
    isInApp: boolean;
    coverImage: string;
    shortID: string;
  }[]
>("get", "template/all");
