import { Flex, Text, useToast } from "@chakra-ui/react";

import { type FC } from "react";
import Button from "components/Button";
import {
  templatesBinStore,
  templatesStore,
} from "utils/store/templates";
import { undoDeleteTemplate } from "apis/templates/undo.delete.template";
import { permanentlyDeleteTemplate } from "apis/templates/permanently.delete.template";

interface IEditTemplateModal {
  templateID: string;
  onClose: () => void;
  // maxWidth: any
}

const PermanentlyDeleteOrRestoreTemplateModal: FC<
  IEditTemplateModal
> = ({ templateID, onClose }) => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  return (
    <Flex
      flexDir={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      fontFamily={"DM Sans, sans-serif"}
      pt={"0.5rem"}
      pb={"1.3rem"}
      // maxW={{base:'26.75rem', lg:'30rem'}}
      // maxH={{ base: '20.375rem', md: '21.25rem' }}
      overflowY={"auto"}
    >
      <Text fontSize="1rem" fontWeight={700} mb={"2rem"}>
        Permanently Delete Or Restore template
      </Text>
      <Flex
        w={"100%"}
        flexDir={"column"}
        gap={"0.62rem"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Button
          text={"Restore"}
          isDisabled={false}
          onClick={() => {
            undoDeleteTemplate({
              body: {
                templateID,
              },
              onCompleted(data) {
                templatesStore.value = {
                  ...templatesStore.value,
                  templates: data.active,
                };
                templatesBinStore.value = {
                  ...templatesBinStore.value,
                  templates: data.deleted,
                };
                toast({
                  status: "success",
                  description: "template restored",
                });
                onClose();
              },
              onError(err) {
                toast({
                  status: "error",
                  description: err,
                });
              },
            });
          }}
        />
        <Button
          text={"Permanently Delete"}
          isDisabled={false}
          onClick={() => {
            permanentlyDeleteTemplate({
              body: {
                templateID,
              },
              onCompleted(data) {
                templatesStore.value = {
                  ...templatesStore.value,
                  templates: data.active,
                };
                templatesBinStore.value = {
                  ...templatesBinStore.value,
                  templates: data.deleted,
                };
                toast({
                  status: "success",
                  description: "template deleted",
                });
                onClose();
              },
              onError(err) {
                toast({
                  status: "error",
                  description: err,
                });
              },
            });
          }}
        />
      </Flex>
    </Flex>
  );
};

export default PermanentlyDeleteOrRestoreTemplateModal;
