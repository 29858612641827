import { Box, Image, type ResponsiveValue } from '@chakra-ui/react'
import { type FC } from 'react'

interface IAvatarImage {
  image?: string | ArrayBuffer | null
  username?: string
  filterValue?: string
  w?: ResponsiveValue<string & any>
  h?: ResponsiveValue<string & any>
  imageBorderRadius: string
  border?: string
}

const AvatarImage: FC<IAvatarImage> = ({
  image,
  w,
  h,
  filterValue,
  username,
  imageBorderRadius,
  border
}: IAvatarImage) => {
  const userImage = image ?? '/images/avatar.webp'

  return (
    <Box
      borderRadius={imageBorderRadius}
      border={border ?? '1px solid rgba(231,166,26,0.12)'}
      boxShadow={'1px 1px 15px 5px rgba(231,166,26,0.12)'}
      w={w ?? { base: '84px', sm: '120px' }}
      h={h ?? { base: '84px', sm: '120px' }}
      // ml={"auto"}
    >
      <Image
        borderRadius={imageBorderRadius}
        src={userImage as string}
        minW={w ?? { base: '84px', sm: '120px' }}
        minH={w ?? { base: '84px', sm: '120px' }}
        w={w ?? { base: '84px', sm: '120px' }}
        h={h ?? { base: '84px', sm: '120px' }}
        boxSize={w ?? { base: '84px', sm: '120px' }}
        objectFit="cover"
        alt={username}
        loading={'lazy'}
        filter={filterValue ?? ''}
      />
    </Box>
  )
}

export default AvatarImage
