import { Box, Flex, Image, Input, Text, useColorMode, useToast } from '@chakra-ui/react'
import Button from 'components/Button'
import {
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
  useCallback,
  useRef,
  type FC,
} from 'react'

interface IImageModal {
  fromThirdParty: (imageUrl: string) => void
  fromGallery: (imageUrl: File) => void
  setModalType: Dispatch<SetStateAction<'image modal' | 'unsplash' | 'giphy'>>
}

const ImageModal: FC<IImageModal> = ({
  fromGallery,
  fromThirdParty,
  setModalType,
}: IImageModal) => {
  const { colorMode } = useColorMode()

  const inputFile = useRef<HTMLInputElement | any>()

  const toast = useToast({
    position: 'top',
    containerStyle: {
      zIndex: 9,
    },
  })

  const changeFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target && e.target.files) {
        const file = e.target.files[0];
        if (file) {
          const imgSize = file?.size;
          if (!imgSize) return;        // don't upload what you cannot ascertain
          if (imgSize <= 10_485_760) { // cloudinary image size upload limit
            fromGallery(file)
          }else {
            toast({
              status: 'error',
              description: 'The file size should not exceed 10mb',
            })
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromGallery],
  )

  return (
    <Box
      flexDir={'column'}
      display="flex"
      gap="0.5rem"
      textAlign={'center'}
      padding="1.9rem 1.37rem"
      m={'0 auto'}
      maxWidth={'23rem'}
      justifyContent={'center'}
    >
      <Text
        fontSize={'1.125rem'}
        color={colorMode === 'light' ? '#040404' : '#f5f5f5'}
        fontWeight={500}
        pb={'1.37rem'}
        textAlign={'center'}
      >
        Select image from
      </Text>
      <Box maxWidth="23rem" w={'100%'}>
        <Button
          text={
            <Flex alignItems={'center'} justifyContent={'center'}>
              <Image
                src="svgs/gallery.svg"
                mr={'0.5rem'}
                display={'inline-flex'}
              />
              <Text display={'inline-flex'}>Gallery</Text>
            </Flex>
          }
          fontSize={'1rem'}
          fontWeight={'500'}
          // size="md"
          onClick={() => {
            inputFile.current.click()
          }}
          opacity={'1'}
          border={'none'}
          textHoverColor={'#fff'}
          width="100%"
          h={'3.125rem'}
          borderRadius={'0.5rem'}
          maxWidth="23rem"
          bg={colorMode === 'light' ? '#f9f9f9' : '#222'}
          color={colorMode === 'light' ? '#999' : '#c4c4c4'}
          boxShadow={'none'}
          type="submit"
        />
        <Input
          type={'file'}
          name="select-file"
          ref={inputFile}
          display="none"
          accept="image/png, image/jpeg"
          onChange={changeFile}
        />
      </Box>
      <Button
        text={
          <Flex alignItems={'center'} justifyContent={'center'}>
            <Image
              src="svgs/unsplash.svg"
              mr={'0.5rem'}
              display={'inline-flex'}
            />
            <Text display={'inline-flex'}>Unsplash</Text>
          </Flex>
        }
        fontSize={'1rem'}
        fontWeight={'500'}
        // size="md"
        onClick={() => {
          setModalType('unsplash')
        }}
        opacity={'1'}
        border={'none'}
        textHoverColor={'#fff'}
        width="100%"
        maxWidth="23rem"
        h={'3.125rem'}
        borderRadius={'0.5rem'}
        bg={colorMode === 'light' ? '#f9f9f9' : '#222'}
        color={colorMode === 'light' ? '#999' : '#c4c4c4'}
        boxShadow={'none'}
        type="submit"
      />
      <Button
        text={
          <Flex alignItems={'center'} justifyContent={'center'}>
            <Image src="svgs/giphy.svg" mr={'0.5rem'} display={'inline-flex'} />
            <Text display={'inline-flex'}>Giphy</Text>
          </Flex>
        }
        fontSize={'1rem'}
        fontWeight={'500'}
        // size="md"
        onClick={() => {
          setModalType('giphy')
        }}
        opacity={'1'}
        border={'none'}
        textHoverColor={'#fff'}
        width="100%"
        maxWidth="23rem"
        h={'3.125rem'}
        borderRadius={'0.5rem'}
        bg={colorMode === 'light' ? '#f9f9f9' : '#222'}
        color={colorMode === 'light' ? '#999' : '#c4c4c4'}
        boxShadow={'none'}
        type="submit"
      />
    </Box>
  )
}

export default ImageModal
