import {
  type ChakraTheme,
  extendTheme,
  type StyleFunctionProps,
} from '@chakra-ui/react'
import { colors } from './colors'
import '@fontsource/dm-sans/700.css'
import '@fontsource/dm-sans/500.css'
import '@fontsource/dm-sans/400.css'
import '@fontsource/oswald/700.css'
import '@fontsource/oswald/500.css'
import '@fontsource/oswald/400.css'
import '@fontsource/lobster/400.css'

interface configType extends Partial<ChakraTheme> {}

const config: configType = {
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: true,
    disableTransitionOnChange: false,
    cssVarPrefix: 'peiges-ui',
  },
}

const theme = extendTheme({
  ...config,
  colors,
  fonts: {
    heading: `"DM Sans",  'Oswald', 'Lobster' sans-serif`,
    body: `"DM Sans",  'Oswald', 'Lobster' sans-serif`,
  },
  styles: {
    global: ({ colorMode }: StyleFunctionProps) => ({
      body: () => ({
        fontFamily: `"DM Sans", 'Oswald', 'Lobster', sans-serif`,
        textAlign: 'center',
        bg: `${colorMode === 'dark' ? 'black' : 'white'}`,
        transitionProperty: 'all',
        transitionDuration: '0.5s',
      }),
    }),
  },
})

export default theme
