import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Text,
  Image,
  useColorModeValue,
  useToast,
  useColorMode,
} from '@chakra-ui/react'
import { Loader } from 'components'
import { PlainInput } from 'components/Input'
import SearchCategory from 'components/SearchCategory'
import EnvConfig from 'config/env.config'
import { type FC, useEffect, useState } from 'react'
import { createApi } from 'unsplash-js'

const unsplashApi = createApi({
  accessKey: EnvConfig.unsplashAccessKey,
})

interface IUnSplashModal {
  selectUnsplashImage: (imageUrl: string) => void
  onClose: () => void
}

const UnSplashModal: FC<IUnSplashModal> = ({
  selectUnsplashImage,
  onClose
}: IUnSplashModal) => {
  const [query, setQuery] = useState('')
  const [photos, setPhotos] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [categoryOpen, setCategoryOpen] = useState(true)

  const { colorMode } = useColorMode()

  const toast = useToast({
    position: 'top',
    containerStyle: {
      zIndex: 9,
    },
  })

  useEffect(() => {
    const getImages = (): void => {
      setLoading(true)
      unsplashApi.search
        .getPhotos({
          query,
          orientation: 'landscape',
          perPage: EnvConfig.unsplashPerPage,
        })
        .then((result: any) => {
          if (result.response) {
            setPhotos(result.response.results)
            setLoading(false)
          } else if (result.status === 401) {
            toast({
              status: 'success',
              description: 'An error occured. Kindly try again later',
            })
          }
        })
        .catch(err => {
          toast({ status: 'error', description: err.message })
        })
        .finally(() => {
          setCategoryOpen(false)
        })
    }

    if (query) {
      const debounce = setTimeout(getImages, 1000)

      setLoading(false)
      return () => {
        clearTimeout(debounce)
      }
    }
  }, [query, toast])

  return (
    <Box>
      <Flex>
        <Box
          onClick={onClose}
          borderRadius="50%"
          bgColor="rgba(194, 193, 193, 0.3)"
          width="28px"
          height="28px"
          display="flex"
          justifyContent={'center'}
          alignItems="center"
          cursor={'pointer'}
        >
          <ChevronLeftIcon
            color={colorMode === 'light' ? 'black' : 'white'}
            w={6}
            h={6}
          />
        </Box>
        <Text
          pb="1.12rem"
          fontSize={'1.125rem'}
          textAlign={'center'}
          color={useColorModeValue('black', 'white')}
          maxW={'44rem'}
          w={'90%'}
        >
          Unsplash
        </Text>
      </Flex>
      <Box>
        <PlainInput
          placeholder="Search for an image"
          name={'unsplash-image'}
          _placeholder={colorMode === 'light' ? '#999' : '#c4c4c4'}
          color={useColorModeValue('black', 'white')}
          value={query}
          background={colorMode === 'light' ? '#f9f9f9' : '#333333'}
          onChange={e => {
            setQuery(e.target.value)
          }}
          showMessage={false}
        />
        {(categoryOpen || !query) && <SearchCategory onClick={setQuery} />}
      </Box>
      <Flex
        pos={'relative'}
        justifyContent={'center'}
        flexWrap="wrap"
        gap="3px"
        pt="1rem"
      >
        {loading && (
          <Flex
            pos={'absolute'}
            justifyContent={'center'}
            left={'10px'}
            maxH={'400px'}
            h={'full'}
          >
            <Box p={'5px 10px'} h={'inherit'}>
              <Loader pageName="Loading" />
            </Box>
          </Flex>
        )}
        {photos &&
          query &&
          photos.map((photo, i) => {
            return (
              <Box
                key={i}
                onClick={() => {
                  selectUnsplashImage(photo.urls.regular)
                }}
                cursor="pointer"
                flexBasis={{ sm: '0 0 33%' }}
                width={{ sm: '33%' }}
              >
                <Image
                  src={photo.urls.small}
                  alt={photo.description || ''}
                  loading={'lazy'}
                />
                <Text
                  py="0.5rem"
                  color={colorMode === 'dark' ? 'white' : 'black'}
                  fontSize="14px"
                >
                  By{' '}
                  <span style={{ textDecoration: 'underline' }}>
                    {photo.user.username}
                  </span>
                </Text>
              </Box>
            )
          })}
      </Flex>
    </Box>
  )
}

export default UnSplashModal
