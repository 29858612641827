import { type FC, Suspense } from "react";
import { Route, Routes as RouteWrapper } from "react-router-dom";
import { ROUTES } from "./links";
import PageLoader from "../compositions/PageLoader";
import { AuthLayout, DashboardLayout } from "../Layout";

import Login from "../pages/Auth/Login";
import Users from "../pages/Dashboard/User";
import AuthRouteProvider from "./AuthRoutesProvider";
import Templates from "pages/Dashboard/Template";
import Overview from "../pages/Dashboard/Overview";
import Editor from "pages/Editor";
import { Box } from "@chakra-ui/react";
import { ColorModeSwitcher } from "ColorModeSwitcher";
import Feedback from "pages/Dashboard/Feedback";
import Contacts from "pages/Dashboard/Contact";
import AdminCmp from "pages/Dashboard/Admins";
import TemplatesBin from "pages/Dashboard/TemplateBin";

// const Login = lazy(async () => await import('pages/Auth/Login'))

const Routes: FC = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Box pos={'absolute'} top={'1rem'} right={'0.625rem'} zIndex={11}>
        <ColorModeSwitcher justifySelf="flex-end" />
      </Box>
      <RouteWrapper>
        <Route path={ROUTES.login} element={<AuthLayout />}>
          <Route index element={<Login />} />
        </Route>
        <Route
          path={ROUTES.dashboard}
          element={
            <AuthRouteProvider>
              <DashboardLayout />
            </AuthRouteProvider>
          }
        >
          <Route index element={<Overview />} />
          <Route path={ROUTES.users} element={<Users />} />
          <Route path={ROUTES.templates} element={<Templates />} />
          <Route path={ROUTES.templatesBin} element={<TemplatesBin />} />
          <Route path={ROUTES.contacts} element={<Contacts />} />
          <Route path={ROUTES.admins} element={<AdminCmp />} />
          <Route path={ROUTES.feedback} element={<Feedback />} />
          <Route path={ROUTES.templateEditor} element={<Editor />} />
        </Route>
      </RouteWrapper>
    </Suspense>
  );
};

export default Routes;
