export enum PeigePublishMode {
  AUTO_PUBLISH = "autopublish",
  MANUAL = "manual"
}

export type User = {
  _id: string,
  username: string,
  peige_views: number,
  link_clicks: number,
  isPublished: boolean,
  publish_mode: PeigePublishMode,
  link: string,
  createdAt: Date,
};
