import { Flex } from "@chakra-ui/react";
import { type FC } from "react";
import { type LogoMode } from "typings/interface";

const LogoSection: FC<LogoMode> = ({ colorMode }: LogoMode) => {
  const color = colorMode === 'light' ? "#000000" : "#F5F5F5"
  return (
    <Flex
      justify={"center"}
      align={"center"}
      w={{ base: "24px", md: "40px" }}
      h={{ base: "24px", md: "40px" }}
      // minW={{ base: "24px", md: "40px" }}
      borderRadius={"50%"}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 36 31" fill="none">
        <path d="M24.7695 30.8868H4.15751C2.98638 30.8868 1.8738 30.3545 1.05401 29.4673C0.292769 28.5802 -0.117127 27.3973
         0.0585426 26.2144L2.10803 8.94429C2.34225 6.87424 4.09896 5.27734 6.207 5.27734H26.819C27.9901 5.27734 29.1027 5.80964
         29.9225 6.69681C30.6837 7.58397 31.0936 8.76685 30.9179 9.94974L28.8684 27.2199C28.6342 29.2899 26.8775 30.8868 24.7695
         30.8868ZM2.98638 9.06258L0.936893 26.3327C0.81978 27.279 1.11256 28.1662 1.69813 28.8759C2.2837 29.5856 3.16205 29.9405
          4.09895 29.9405H24.7109C26.3505 29.9405 27.6973 28.6985 27.9315 27.1016L29.981 9.83145C30.0981 8.88514 29.8053 7.99798
          29.2198 7.28825C28.6342 6.57852 27.7559 6.22365 26.819 6.22365H6.207C4.56741 6.22365 3.16205 7.46568 2.98638 9.06258Z" fill={color}/>
        <path d="M28.9858 26.6295H8.37382C7.20269 26.6295 6.03155 26.0972 5.27032 25.2101C4.50908 24.3229 4.09918 23.14 4.27485
        21.898L6.32434 4.68703C6.55856 2.55783 8.37382 0.960938 10.4819 0.960938H31.0353C32.2064 0.960938 33.3775 1.49324 34.1388
        2.3804C34.9 3.26756 35.3099 4.45045 35.1342 5.69248L33.0847 22.9035C32.8505 25.0327 31.0938 26.6295 28.9858 26.6295ZM7.20269
        4.80531L5.1532 22.0163C5.03609 22.9626 5.32887 23.8498 5.973 24.5595C6.55856 25.2692 7.49547 25.6832 8.37382
        25.6832H28.9858C30.6254 25.6832 32.0307 24.4412 32.2064 22.7852L34.2559 5.57419C34.373 4.62788 34.0802 3.74072 33.4361
        3.03099C32.8505 2.32126 31.9136 1.90725 31.0353 1.90725H10.4233C8.78372 1.90725 7.37836 3.14928 7.20269 4.80531Z" fill={color}/>
      </svg>
    </Flex>
  );
};

export default LogoSection;
