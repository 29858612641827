import { Flex } from '@chakra-ui/react'
import { type FC } from 'react'
import { Outlet } from 'react-router-dom'

const Auth: FC = () => {
  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={'black'}
    >
      <Outlet />
    </Flex>
  )
}

export default Auth
