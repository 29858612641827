import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Image,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";

import ResponsiveModal from "components/ResponsiveModal";
import { getAllTemplates } from "apis/templates/get.all.templates";
import { AdminPermissionMatrixStore } from "utils/admin.permission";
import EditTemplateModal from "components/AppModals/EditTemplateModal";
import CreateTemplateModal from "components/AppModals/CreateTemplateModal";
import {
  CurrentTemplate,
  setCurrentTemplate,
  templatesStore,
} from "utils/store/templates";
import { deleteTemplate } from "apis/templates/delete.template";

const Templates = () => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const p_bo_can_create_template =
    AdminPermissionMatrixStore.value.p_bo_can_create_template;
  const p_bo_can_view_templates =
    AdminPermissionMatrixStore.value.p_bo_can_view_templates;

  const [createTemplateModalOpen, setCreateTemplateModalOpen] =
    useState(false);
  const [editTemplateModalOpen, setEditTemplateModalOpen] =
    useState(false);

  const [editTemplateData, setEditTemplateData] = useState({
    templateID: "",
    templateName: "",
    description: "",
    coverImage: "",
    isInApp: false,
  });

  const handleDeleteTemplate = (templateToBeDeleted: string) => {
    deleteTemplate({
      body: {
        templateID: templateToBeDeleted,
      },
      onCompleted(_templatesData) {
        templatesStore.value = {
          ...templatesStore.value,
          templates: _templatesData,
        };
        toast({
          status: "success",
          description: "template moved to bin",
        });
      },
      onError(err) {
        toast({
          status: "error",
          description: "could not move template to bin",
        });
      },
    });
  };
  const toggleCreateTemplateModal = () => {
    setCreateTemplateModalOpen(!createTemplateModalOpen);
  };
  const toggleEditTemplateModal = () => {
    setEditTemplateModalOpen(!editTemplateModalOpen);
  };
  const navigate = useNavigate();
  const templates = templatesStore.value.templates;

  useEffect(() => {
    if (p_bo_can_view_templates)
      getAllTemplates({
        onCompleted: (_templatesData) => {
          templatesStore.value = {
            ...templatesStore.value,
            templates: _templatesData,
          };
        },
        onError: () => {
          //
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection={"column"} gap={"15px"} w={"full"}>
      <Flex justifyContent={"space-between"}>
        {p_bo_can_create_template ? (
          <Button
            w={"max-content"}
            border={"1px solid black"}
            onClick={toggleCreateTemplateModal}
          >
            create
          </Button>
        ) : (
          <></>
        )}
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          cursor={"pointer"}
        >
          <DeleteIcon onClick={() => navigate("/templates/bin")} />
          <Text fontSize={"12px"} textDecoration={"underline"}>
            Bin
          </Text>
        </Flex>
        <Button
          // color={"white"}
          // textDecoration={"underline"}
          // cursor={"pointer"}
          border={"1px solid black"}
          onClick={() => navigate("/")}
        >
          back to dashboard
        </Button>
      </Flex>
      <Flex
        gap={"1rem"}
        flexWrap={"wrap"}
        h={"85vh"}
        overflowY={"auto"}
        className={"no-scrollbar"}
        pb={"50px"}
        mr={"auto"}
      >
        {p_bo_can_view_templates ? (
          templates.map((template) => {
            const {
              templateID,
              templateName,
              description,
              isInApp,
              coverImage,
              shortID,
            } = template;

            return (
              <TemplateCard
                key={templateID}
                templateID={templateID}
                templateName={templateName}
                isInApp={isInApp}
                shortID={shortID}
                coverImage={coverImage}
                textColor={"white"}
                openTemplateEditor={() => {
                  CurrentTemplate.value = { templateID, templateName, shortID };
                  setCurrentTemplate({templateID, templateName, shortID})
                  navigate("/template-editor")
                }
                }
                editTemplate={() => {
                  setEditTemplateData({
                    templateID,
                    templateName,
                    description,
                    isInApp,
                    coverImage,
                  });
                  setEditTemplateModalOpen(true);
                }}
                onDelete={() => {
                  handleDeleteTemplate(templateID);
                }}
              />
            );
          })
        ) : (
          <></>
        )}
      </Flex>
      <ResponsiveModal
        isOpen={createTemplateModalOpen}
        toggle={toggleCreateTemplateModal}
        modalType="create template modal"
        maxWidth="30rem"
        content={<CreateTemplateModal />}
      />
      <ResponsiveModal
        isOpen={editTemplateModalOpen}
        toggle={toggleEditTemplateModal}
        modalType="edit template modal"
        maxWidth="30rem"
        content={
          <EditTemplateModal
            templateID={editTemplateData.templateID}
            templateName={editTemplateData.templateName}
            description={editTemplateData.description}
            isInApp={editTemplateData.isInApp}
            coverImage={editTemplateData.coverImage}
            onClose={toggleEditTemplateModal}
          />
        }
      />
    </Flex>
  );
};

const TemplateCard: FC<{
  templateID: string;
  shortID: string;
  templateName: string;
  isInApp: boolean;
  textColor: string;
  coverImage: string;
  openTemplateEditor: () => void;
  editTemplate: () => void;
  onDelete: () => void;
}> = ({
  templateID,
  templateName,
  shortID,
  isInApp,
  textColor,
  onDelete,
  coverImage,
  openTemplateEditor,
  editTemplate,
}) => {
  return (
    <Flex
      w={{ base: "90px", md: "180px" }}
      h={{ base: "195px", md: "390px" }}
      bgColor={"white"}
      borderRadius={"0.625rem"}
      flexDirection={"column"}
      position={"relative"}
    >
      <Tooltip label={"Move to bin"}>
        <Flex
          w={"30px"}
          h={"30px"}
          justifyContent={"center"}
          alignItems={"center"}
          borderRadius={"20px"}
          bgColor={"white"}
          position={"absolute"}
          right={2}
          top={2}
          border={"1px solid black"}
          cursor={"pointer"}
          onClick={onDelete}
        >
          <CloseIcon color={"black"} />
        </Flex>
      </Tooltip>
      <Image
        borderTopRadius={"0.625rem"}
        w={"full"}
        h={"85%"}
        cursor={"pointer"}
        onClick={editTemplate}
        objectFit={"cover"}
        src={coverImage}
      />
      <Flex
        borderTopRadius={"0.625rem"}
        // flexDirection={"column"}
        w={"full"}
        h={"15%"}
        bgColor={"white"}
        justifyContent={"space-between"}
        alignItems={"center"}
        fontSize={{ base: "10px", md: "12px" }}
        px={{ base: "5px", md: "20px" }}
      >
        <Text isTruncated color={"black"}>
          {templateName}
        </Text>
        <Flex
          bgColor={"black"}
          w={"40px"}
          h={{ base: "20px", md: "30px" }}
          borderRadius={"50px"}
          justifyContent={"center"}
          alignItems={"center"}
          cursor={"pointer"}
          color={textColor}
          onClick={() => {
            setCurrentTemplate({templateID, templateName, shortID});
            openTemplateEditor();
          }}
        >
          open
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Templates;
