import { generateAPI } from "apis/api.generator";
import { User } from "types/User";


export const getUsers = generateAPI<
  any,
  {
    total_users: number;
    users: User[]
  }
>("get", "dashboard/individuals");
