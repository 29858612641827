import { BASE_API_REQ, DELETE, GET, POST, PUT } from "utils/base.api";

type API_GENERATOR<InoutType extends Partial<BASE_API_REQ>, ReturnType> = {
  onCompleted: (data: ReturnType, token: string) => void;
  onError: (err?: any) => void;
} & Partial<InoutType & BASE_API_REQ>;

type ApiMethods = 
  | "get"
  | "put"
  | "post"
  | "delete";

const API_METHOD = {
  "get": GET,
  "put": PUT,
  "post": POST,
  "delete": DELETE,
};

export function generateAPI<InputType extends Partial<BASE_API_REQ>, ReturnType>(apiMethod: ApiMethods, route: string) {
  return async ({ onCompleted, onError, ...rest }: API_GENERATOR<InputType, ReturnType>) => {
    try {
      const data = await API_METHOD[apiMethod]<ReturnType>({
        ext: route,
        ...rest,
      });
      if (data.error) {
        onError(data.error);
        return;
      }

      if (data.statusCode === 200 || data.statusCode === 201) {
        onCompleted(data.payload, data.token!);
        return;
      }
      
      if (data.statusCode >= 400 || data.statusCode !== 200 ) {
        onError(data.message);
        return;
      }
    } catch (error: any) {
      onError(error?.message);
    }
  }
};
