import { Divider, Flex, Text } from '@chakra-ui/react'
import Button from 'components/Button'
import ResponsiveModal from 'components/ResponsiveModal'
import { type FC } from 'react'

interface IConfirmationModal {
  toggleModal: () => void
  openModal: boolean
  handleRemoveImg: () => void
  text: string
  subText?: string
}

const ConfirmationModal: FC<IConfirmationModal> = ({
  toggleModal,
  text,
  subText,
  openModal,
  handleRemoveImg,
}: IConfirmationModal) => {
  return (
    <ResponsiveModal
      isOpen={openModal}
      toggle={toggleModal}
      maxWidth="500px"
      minH="30vh"
      content={
        <Flex
          height={'fit-content'}
          width={'full'}
          flexDir={'column'}
          justify={'center'}
          align={'center'}
          my={'auto'}
          gap={2}
          cursor={'pointer'}
        >
          <Text>{text}</Text>
          <Text fontSize={"12px"}>{subText}</Text>
          <Divider orientation="horizontal" />
          <Flex mt={3}>
            <Button
              text={'No'}
              // bgColor={'#F5CE6F'}
              h={'8'}
              onClick={toggleModal}
              padding={'0.5rem 2rem'}
              rounded={'lg'}
              mr={3}
            />
            <Button
              text={'Yes'}
              h={'8'}
              padding={'0.5rem 2rem'}
              onClick={handleRemoveImg}
              rounded={'lg'}
              bgColor={'#B83746'}
              variant="ghost"
            />
          </Flex>
        </Flex>
      }
    />
  )
}

export default ConfirmationModal
