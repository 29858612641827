import { chakra, type ImageProps, forwardRef } from "@chakra-ui/react";

const Logo = forwardRef<ImageProps, "img">((props, ref) => {
  return (
    <chakra.img
      src={"/images/peiges-icon.webp"}
      loading={"lazy"}
      ref={ref}
      {...props}
    />
  );
});

export default Logo;
