import { z } from "zod";

export const EnvConfigSchema = z.object({
  apiBaseUrl: z.string(),
  appLink: z.string(),
  publicUrl: z.string(),
  appUrl: z.string(),
  unsplashAccessKey: z.string(),
  unsplashPerPage: z.number(),
  giphyAccessKey: z.string(),
});

type EnvConfig = z.infer<typeof EnvConfigSchema>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
const EnvConfig = {
  apiBaseUrl: process.env.REACT_APP_BASE_URL,
  appLink: process.env.REACT_APP_APP_LINK,
  publicUrl: process.env.REACT_APP_PUBLIC_URL,
  appUrl: process.env.REACT_APP_URL,
  unsplashAccessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
  unsplashPerPage: Number(process.env.REACT_APP_UNSPLASH_PER_PAGE),
  giphyAccessKey: process.env.REACT_APP_GIPHY_ACCESS_KEY,

} as EnvConfig;

EnvConfigSchema.parse(EnvConfig);

export default EnvConfig;
