import { generateAPI } from "apis/api.generator";
import { Template } from "types/Template";


export const modifyPeigeFromTemplate = generateAPI<
  {
    body: {
      templateID: string;
      peigeUsername: string;
    };
  },
  Template
>("post", "template/modify-peige");
