// import { useMutation } from '@apollo/client'
import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { type TopbarInterface } from './types'
import { type FC, useState, useContext } from 'react'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'routes/links'
import { Button, Loader, ResponsiveModal } from 'components'
import theme from 'theme'
import ShareModal from 'components/AppModals/ShareModal'

// import { CurrentUserContext } from 'routes/AuthRoutesProvider'
import EnvConfig from 'config/env.config'
import LinkTemplateToPeigeModal from 'components/AppModals/LinkTemplateToPeigeModal'
import { AdminPermissionMatrixStore } from 'utils/admin.permission'
import { screenshotTemplate } from 'apis/templates/screenshot.template'
import { CurrentTemplate } from 'utils/store/templates'
import { CurrentUserContext } from 'routes/AuthRoutesProvider'
// import { PUBLISH_PEIGE_LIVE_MUTATION } from 'apolloClient/mutations/publishPeige'

// export const ChangeState = signal<'dirty' | 'clean'>('clean')
// export const ShareModalState = signal({
//   openShareDrawer: false,
// });
// export const LinkPeigeFromTemplateState = signal({
//   openLinkDrawer: false,
// });

const Topbar: FC<TopbarInterface> = ({
  colorMode,
  // editorActive,
  isEditor,
  isWeb,
  username,
  // view,
  // setView,
}: TopbarInterface) => {
  const p_bo_can_clone_template = AdminPermissionMatrixStore.value.p_bo_can_clone_template;
  const { appState, setAppState } = useContext(CurrentUserContext)
  const { viewContext, openShareDrawer, openLinkDrawer } = appState

  const navigate = useNavigate()
  const currentDate = new Date()
  const today = `${currentDate.toLocaleString('default', {
    month: 'long',
  })}, ${currentDate.getDate()}`
  const [saving] = useState(false)

  // const toggleShareModal = () => {
  //   ShareModalState.value = { openShareDrawer: !ShareModalState.value.openShareDrawer};
  // }
  // const toggleLinkModal = () => {
  //   LinkPeigeFromTemplateState.value = { openLinkDrawer: !LinkPeigeFromTemplateState.value.openLinkDrawer};
  // }

  const darkBg = theme.colors.background.primary._dark
  const lightBg = theme.colors.background.primary.default

  const colorModeBg = colorMode === 'light' ? lightBg : darkBg
  const colorModeIcon = useColorModeValue('black', 'white')

  const [ isScreenshotingTemplate, setIsScreenshotingTemplate ] = useState(false);

  return (
    <Flex
      top={0}
      w={'100%'}
      h={'70px'}
      zIndex={10}
      pos={{ base: 'fixed', md: 'sticky' }}
      justify={'center'}
      align={'center'}
      bg={colorModeBg}
      boxShadow={`0px 1px 0px ${
        colorMode === 'light'
          ? 'rgba(0, 0, 0, 0.05)'
          : 'rgba(255, 255, 255, 0.05)'
      }`}
    >
      <Flex
        w={'full'}
        maxW={'1160px'}
        h={'69px'}
        justify={'center'}
        align={'center'}
        px={'16px'}
      >
        {isEditor ? (
          <>
            <ResponsiveModal
              isOpen={openShareDrawer}
              toggle={() => {
                setAppState({
                  ...appState,
                  openShareDrawer: false
                })
              }}
              modalType="share modal"
              maxWidth="30rem"
              content={<ShareModal templateName={CurrentTemplate.value.templateName} shortID={CurrentTemplate.value.shortID} />}
            />
            <ResponsiveModal
              isOpen={openLinkDrawer}
              toggle={() => {
                setAppState({
                  ...appState,
                  openLinkDrawer: false,
                })
              }}
              modalType="link modal"
              maxWidth="30rem"
              content={<LinkTemplateToPeigeModal toggleModal={() => {
                setAppState({
                  ...appState,
                  openLinkDrawer: !openLinkDrawer
                })
              }} />}
            />
            <Flex
              w={'full'}
              paddingRight={{ base: '35px', md: '30px' }}
              paddingLeft={{ base: '0px', md: '10px' }}
              justify={{ base: 'space-between' }}
              align={'center'}
            >

              {isWeb ? (
                <>
                  <Text
                    fontSize={'14px'}
                    cursor={'pointer'}
                    onClick={() => {
                      // ShareModalState.value = { openShareDrawer : true }
                      setAppState({
                        ...appState,
                        openShareDrawer: true
                      })
                    }}
                    mr={'16px'}
                  >
                    {EnvConfig.publicUrl.split('//')[1]}/template/
                    {CurrentTemplate.value.shortID}
                  </Text>
                </>
              ) : (
                <Flex align={'center'} justify={'center'}>
                  <Box
                    onClick={() => {
                      navigate(ROUTES.dashboard)
                    }}
                    borderRadius="50%"
                    bgColor="rgba(194, 193, 193, 0.3)"
                    width="28px"
                    height="28px"
                    display="flex"
                    justifyContent={'center'}
                    alignItems="center"
                    cursor={'pointer'}
                  >
                    <ChevronLeftIcon
                      color={colorMode === 'light' ? 'black' : 'white'}
                      w={6}
                      h={6}
                    />
                  </Box>
                  <Flex
                    align={'center'}
                    cursor={'pointer'}
                    onClick={() => {
                      // ShareModalState.value = {openShareDrawer: true};
                      setAppState({
                        ...appState,
                        openShareDrawer: true
                      })
                    }}
                  >
                    <Text fontSize={'12px'} fontWeight={500} mr={'4px'} ml={2}>
                      {CurrentTemplate.value.templateName.length > 8
                        ? `${CurrentTemplate.value.templateName.substring(6)}...`
                        : CurrentTemplate.value.templateName}
                    </Text>
                    <Image 
                      src={colorModeIcon === 'white' ? `svgs/mobile.share.dark.svg` : `svgs/mobile.share.white.svg`}
                    />
                  </Flex>
                </Flex>
              )}
              
              <Flex
                py="15px"
                w={'fit-content'}
                gap={{base: '5px', md: '7px'}}
                alignItems="center"
              >
                <Button
                  text={'Screenshot'}
                  h={'fit-content'}
                  color={colorMode === 'dark' ? 'white' : 'black'}
                  bg={'transparent'}
                  fontSize={{base: '11px', md: '14px'}}
                  fontWeight="500"
                  borderRadius={'8px'}
                  padding={'5px 8px'}
                  border={'1px solid'}
                  _hover={{
                    color: colorMode === 'dark' ? 'white' : 'black',
                    backgroundColor: 'transparent',
                    border: '1px solid',
                  }}
                  _active={{
                    color: colorMode === 'dark' ? 'white' : 'black',
                    backgroundColor: 'transparent',
                    border: '1px solid',
                  }}
                  borderColor={colorMode === 'dark' ? 'white' : 'black'}
                  isDisabled={isScreenshotingTemplate}
                  onClick={() => {
                    setIsScreenshotingTemplate(true)
                    screenshotTemplate({
                      body: {
                        templateID: CurrentTemplate.value.templateID,
                      },
                      onCompleted(data, token) {
                        setIsScreenshotingTemplate(false)
                      },
                      onError(err) {
                        setIsScreenshotingTemplate(false)
                      },
                    });
                  }}
                />
                <Button
                  text={viewContext === 'Editor' ? 'Preview' : 'Editor'}
                  h={'fit-content'}
                  color={colorMode === 'dark' ? 'white' : 'black'}
                  bg={'transparent'}
                  fontSize={{base: '11px', md: '14px'}}
                  fontWeight="500"
                  borderRadius={'8px'}
                  padding={'5px 8px'}
                  border={'1px solid'}
                  _hover={{
                    color: colorMode === 'dark' ? 'white' : 'black',
                    backgroundColor: 'transparent',
                    border: '1px solid',
                  }}
                  _active={{
                    color: colorMode === 'dark' ? 'white' : 'black',
                    backgroundColor: 'transparent',
                    border: '1px solid',
                  }}
                  borderColor={colorMode === 'dark' ? 'white' : 'black'}
                  // isDisabled={editorActive}
                  onClick={() => {
                    setAppState({
                      ...appState,
                      viewContext: viewContext === 'Editor' ? 'Preview' : 'Editor'
                    })
                  }}
                />

                {
                  p_bo_can_clone_template ?
                  <Button
                    text={'clone'}
                    h={'fit-content'}
                    color={colorMode === 'dark' ? 'white' : 'black'}
                    bg={'transparent'}
                    fontSize={{base: '11px', md: '14px'}}
                    fontWeight="500"
                    borderRadius={'8px'}
                    padding={'5px 8px'}
                    border={'1px solid'}
                    _hover={{
                      color: colorMode === 'dark' ? 'white' : 'black',
                      backgroundColor: 'transparent',
                      border: '1px solid',
                    }}
                    _active={{
                      color: colorMode === 'dark' ? 'white' : 'black',
                      backgroundColor: 'transparent',
                      border: '1px solid',
                    }}
                    borderColor={colorMode === 'dark' ? 'white' : 'black'}
                    // isDisabled={editorActive}
                    onClick={() => {
                      // LinkPeigeFromTemplateState.value = {openLinkDrawer: true};
                      setAppState({
                        ...appState,
                        openLinkDrawer: true
                      })
                    }}
                  /> :
                  <></>
                }

                <Flex align={'center'}>
                  <Box
                    display={saving ? 'flex' : 'none'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    h={'20px'}
                  >
                    <Loader pageName="saving" />
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </>
        ) : (
          <Flex flexDir={'row'}>
            <Image
              src={'/images/dateCal.svg'}
              w={'20px'}
              h={'20px'}
              alt={'Today'}
              loading={'lazy'}
            />
            <Text ml={'5px'} opacity={0.5} fontSize={'14px'}>
              Today
            </Text>
            <Text ml={'5px'} fontSize={'14px'} opacity={0.8}>
              {today}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default Topbar
