import { Box, Flex } from '@chakra-ui/react'
import AvatarMenu from 'Layout/Dashboard/composition/AvatarMenu'
import { type GeneralNavInterface } from 'typings/interface'
import LogoSection from 'Layout/Dashboard/composition/LogoSection'
import { Nav } from 'Layout/Dashboard/composition'
import theme from 'theme'
import { type FC } from 'react'


const BottomNav: FC<GeneralNavInterface> = ({
  colorMode,
  // editorActive,
  isEditor,
  isWeb,
  username,
  avatar,
  openRoute,
}: GeneralNavInterface) => {
  const darkBg = theme.colors.background.primary._dark
  const lightBg = theme.colors.background.primary.default

  return (
    <Box
      display={isEditor && !isWeb ? 'block' : 'none'}
      h={'60px'}
      left={0}
      right={0}
      bottom={0}
      pos={'fixed'}
      // zIndex={8}
      pl={isEditor ? { base: 0, md: '4.5rem' } : 0}
    >
      <Flex
        bg={colorMode === 'light' ? lightBg : darkBg}
        boxShadow={'5px -20px 40px 0 rgba(142, 151, 158, 0.015)'}
        justify={isEditor ? 'center' : 'space-between'}
        align={'center'}
        p={'10px'}
        w={'full'}
        maxW={isEditor ? '768px' : '980px'}
        h={'full'}
        m={'0 auto'}
        gap={isEditor ? '30px' : 0}
      >
        {!isWeb && !isEditor ? (
          <>
            <LogoSection colorMode={colorMode} />
            <Nav openRoute={openRoute} />
            <AvatarMenu
              avatar={avatar}
              username={username}
              openRoute={openRoute}
            />
          </>
        ) : (
          <></>
        )}
      </Flex>
    </Box>
  )
}

export default BottomNav
