import {
  Flex,
  Text,
  useToast,
  Checkbox,
  useColorMode,
} from "@chakra-ui/react";

import { useState, type FC, useCallback } from "react";
import Button from "components/Button";
import { PlainInput } from "components/Input";
import { templatesStore } from "utils/store/templates";
import UploadImage from "components/UploadImage";
import { editTemplate } from "apis/templates/edit.template";
import TextArea from "components/TextArea";

interface IEditTemplateModal {
  templateID: string;
  coverImage: string;
  description: string;
  isInApp: boolean;
  templateName: string;
  onClose: () => void;
  // maxWidth: any
}

const EditTemplateModal: FC<IEditTemplateModal> = ({
  templateID,
  description,
  templateName,
  coverImage,
  isInApp,
  onClose,
}) => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });
  const { colorMode } = useColorMode();

  const [templateData, setTemplateData] = useState({
    templateName,
    description,
  });
  const [imgLoading] = useState(false);
  const [addTemplatetToApp, shouldAddTemplatetToApp] =
    useState(isInApp);
  const [image, setImage] = useState<string | ArrayBuffer | null>(
    coverImage
  );
  const [, setFile] = useState<File | string>("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [modalType, setModalType] = useState<
    "image modal" | "unsplash" | "giphy"
  >("image modal");

  const fromThirdParty = useCallback((imageUrl: string) => {
    setImage(imageUrl);
    setFile(imageUrl);
    setShowImageModal(false);
    setModalType("image modal");
  }, []);

  const fromGallery = useCallback((file: File) => {
    setFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
    setShowImageModal(false);
    setModalType("image modal");
  }, []);

  const handleDescriptionChange: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = (e) => {
    e.preventDefault();
    setTemplateData({
      ...templateData,
      description: e.target.value,
    });
  };

  const handleTemplateNameChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    e.preventDefault();
    if (e.target.value.length <= 20) {
      setTemplateData({
        ...templateData,
        templateName: e.target.value,
      });
    }
  };

  return (
    <Flex
      flexDir={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      fontFamily={"DM Sans, sans-serif"}
      pt={"0.5rem"}
      pb={"1.3rem"}
      // maxW={{base:'26.75rem', lg:'30rem'}}
      // maxH={{ base: '20.375rem', md: '21.25rem' }}
      overflowY={"auto"}
    >
      <Text fontSize="1rem" fontWeight={700} mb={"2rem"}>
        Edit template
      </Text>
      <Flex
        w={"100%"}
        flexDir={"column"}
        mt={"1.88rem"}
        gap={"0.62rem"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Checkbox
          isChecked={addTemplatetToApp}
          onChange={(e) => {
            shouldAddTemplatetToApp(e.target.checked);
          }}
        >
          Add template to App
        </Checkbox>
        <UploadImage
          fromGallery={fromGallery}
          fromThirdParty={fromThirdParty}
          w={"120px"}
          h={"120px"}
          minW={"115px"}
          minH={"115px"}
          image={image}
          placeholder={"/images/upload-image.webp"}
          showImageModal={showImageModal}
          imageIsNotCompulsory={true}
          //   setShowImageModal={setShowImageModal}
          handleRemoveImg={() => {
            setImage("");
            setFile("");
          }}
          isLoading={imgLoading}
          toggleModal={() => {
            const isOff = !showImageModal;
            setShowImageModal(!showImageModal);
            if (isOff) setModalType("image modal");
          }}
          modalType={modalType}
          setModalType={setModalType}
          imageShape={"straight"}
        />
        <Flex
          // bgColor={colorMode === 'light' ? lightIconBg : darkLinkBg}
          py="0.75rem"
          pr={"1.38rem"}
          pl={{ base: "1rem", lg: "1.12rem" }}
          borderRadius="0.5rem"
          maxWidth={{ base: "23rem", md: "25rem" }}
          // h={"3rem"}
          w="100%"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={"auto"}
          flexDir={"column"}
        >
          <PlainInput
            name={"template_name"}
            placeholder="Enter Template Name"
            value={templateData.templateName}
            onChange={handleTemplateNameChange}
            background={colorMode === "light" ? "#FFFFFF" : "#222"}
            color={colorMode === "light" ? "#111" : "#F1F1F1"}
            _placeholder={colorMode === "light" ? "#111" : "#F1F1F1"}
            borderColor={
              colorMode === "light"
                ? "rgba(0, 0, 0, 0.5)"
                : "rgba(255, 255, 255, 0.5)"
            }
          />
          <TextArea
            placeholder="Add a description"
            name={"template_description"}
            onChange={handleDescriptionChange}
            value={templateData.description}
            background={colorMode === "light" ? "#FFFFFF" : "#222"}
            color={colorMode === "light" ? "#111" : "#F1F1F1"}
            border={"1px solid transparent"}
          />
        </Flex>

        <Button
          text={"edit"}
          isDisabled={!templateData.templateName && !image}
          onClick={() => {
            editTemplate({
              body: {
                templateID,
                templateName: templateData.templateName.trim(),
                description: templateData.description,
                isInApp: addTemplatetToApp,
                coverImage: image as string,
              },
              onCompleted(data) {
                templatesStore.value = {
                  ...templatesStore.value,
                  templates: data,
                };
                toast({
                  status: "success",
                  description: "template edited",
                });
                onClose();
              },
              onError(err) {
                toast({
                  status: "error",
                  description: err,
                });
              },
            });
          }}
        />
      </Flex>
    </Flex>
  );
};

export default EditTemplateModal;
