/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, type FC } from 'react'
import { Editor } from 'peiges-editor-pro';
import { Box, useColorMode } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import EnvConfig from 'config/env.config';
import { CurrentUserContext } from 'routes/AuthRoutesProvider';
import { CurrentTemplate } from 'utils/store/templates';

const EditorPage: FC = () => {
  const { appState, setAppState } = useContext(CurrentUserContext)
  const {colorMode} = useColorMode()
  const accessToken = Cookies.get("token") || sessionStorage.getItem("token");
  const unsplashAccessKey = "04eQylg5-Yt5ZzcZ8-Y73Q6MFm-9NLWS1Hr04xD76qo";
  const giphyAccessKey = "k2t5saGwngWNYetDGiaBi6TgtsxpYDaX";

  const { templateID, templateName, shortID } = CurrentTemplate.value

  const changeStateFn = (peigeChangeState: 'clean' | 'dirty') => {
    setAppState({
      ...appState,
      changeState: peigeChangeState,
    })
  };

  
  return (
    <Box
      w={"full"}
    >
      <Editor 
        viewContext={appState.viewContext}
        token={accessToken!}
        apiBaseURL={EnvConfig.apiBaseUrl}
        colorMode={colorMode}
        scheme={"backoffice"}
        currentTemplate={{ shortID, templateID, templateName }}
        changeStateFn={changeStateFn}
        unsplashAccessKey={unsplashAccessKey}
        giphyAccessKey={giphyAccessKey}
        unsplashItemsPerPage={20}
      />
    </Box>
  )
}

export default EditorPage
