import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
} from "@chakra-ui/react";
import { getUsers } from "apis/users/get.users";
import { useEffect, useState } from "react";
import { PeigePublishMode, User } from "types/User";
import Profile from "./Profile";
import { getProfile } from "apis/profile/get.profile";
import { SampleIndividual } from "types/Individual";

const sampleData: User[] = [
  {
    _id: "",
    username: "",
    peige_views: 0,
    link_clicks: 0,
    isPublished: true,
    publish_mode: PeigePublishMode.MANUAL,
    link: "https://peiges.co/",
    createdAt: new Date(),
  },
];

const Users = () => {
  const [users, setUsers] = useState(sampleData);
  const [individual, setIndividual] = useState(SampleIndividual);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getUsers({
      onCompleted: (_users) => {
        setUsers(_users.users);
      },
      onError: () => {
        //
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TableContainer bg={"white"} borderRadius={"0.625rem"}>
        <Table variant="simple">
          <TableCaption>Peiges metrics tracker</TableCaption>
          <Thead>
            <Tr>
              <Th color={"black"}>Date</Th>
              <Th color={"black"}>Username</Th>
              <Th color={"black"}>Peige Views</Th>
              <Th color={"black"}>Link Clicks</Th>
              <Th color={"black"}>isPublished</Th>
              <Th color={"black"}>Publish Mode</Th>
              <Th color={"black"}></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody color={"black"}>
            {users.map(
              ({
                _id,
                username,
                peige_views,
                link_clicks,
                isPublished,
                publish_mode,
                link,
                createdAt
              }) => (
                <Tr key={_id} color={"black"}>
                  <Td>{(new Date(createdAt)).toDateString()}</Td>
                  <Td>{username}</Td>
                  <Td>{peige_views}</Td>
                  <Td>{link_clicks}</Td>
                  <Td>{isPublished ? "True" : "False"}</Td>
                  <Td>{publish_mode}</Td>
                  <Td>
                    <Button
                      bg={"#f5f5f5"}
                      color={"black"}
                      as={"a"}
                      href={link}
                      target="_blank"
                      size={"xs"}
                    >
                      Visit
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      bg={"#f5f5f5"}
                      color={"black"}
                      size={"xs"}
                      onClick={() => {
                        getProfile({
                          query: {
                            individualID: _id,
                          },
                          onCompleted: (data) => {
                            console.log(data);
                            setIndividual(data);
                            setOpenModal(true);
                          },
                          onError: () => {
                            //
                          },
                        });
                      }}
                    >
                      More
                    </Button>
                  </Td>
                </Tr>
              )
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <Profile
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        individual={individual}
      />
    </>
  );
};

export default Users;
