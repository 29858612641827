import { css } from '@emotion/react'

const colorMode = localStorage.getItem('chakra-ui-color-mode')

export const GlobalStyles = css`
  body {
    height: 100%;
    background: ${colorMode === 'dark' ? '#111111' : '#F1F1F1'};
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    width: 0px;
  }
  .no-scrollbar::-webkit-scrollbar-thumb {
    display: none;
  }

  .hovered:hover .content {
    animation: scrollOnHover 1.5s linear forwards;
  }
  .content {
    animation: none;
    cursor: pointer;
  }
  @keyframes scrollOnHover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(calc(-100% + 26.625rem));
    }
  }
  @media screen and (max-width: 959px) {
    .hovered:hover .content {
      animation: none;
    }
  }
  .phone-scroll:hover .content {
    animation: phoneScroll 1s linear forwards;
  }

  @keyframes phoneScroll {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(calc(-100% + 23rem));
    }
  }

  @keyframes tooltip {
    0% {
      bottom: 0%;
    }
    100% {
      bottom: 14%;
    }
  }
  .tooltip {
    animation: tooltip 500ms linear forwards;
  }
  .border-divider {
    position: relative;
  }
  .view-peige:nth-of-type(1) {
    background-color: #fff;
    color: #504646;
  }
  .border-divider::before,
  .border-divider::after {
    position: absolute;
    content: '';
    border-top: 1px solid;
    border-top-color: ${colorMode === 'light' ? '#DAD9D9' : '#222222'};
    top: 50%;
    width: 25%;
    max-width: 120px;
    height: 2px;
    border-radius: 5px;
  }

  .border-divider::before {
    left: 0;
  }

  .border-divider::after {
    right: 0;
  }
`
