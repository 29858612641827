import { type IIcons } from "./icon.types";


export const EditorIcon = ({mode, activeStatus}: IIcons) => {
  const color = {
    light: {
      active: "#040404",
      inactive: "#808080",
    },
    dark: {
      active: "#F5CE6F",
      inactive: "#F5F5F5",
    }
  }[mode][activeStatus];

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M5.92592 18.5253L0 20L1.48148 14.0092L15.463 0L20 4.51613L5.92592 18.5253Z" fill={color}/>
    </svg>
  );
};
