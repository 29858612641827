import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { getContacts } from "apis/contacts/get.contacts";
import { useEffect, useState } from "react";
import { PublicContact } from "types/Contact";

const sampleData: PublicContact[] = [
  {
    _id: "",
    name: "",
    email: "",
    subject: "",
    message: "",
    createdAt: new Date(),
  },
];
const Contact = () => {
  const [contacts, setContacts] = useState(sampleData);
  useEffect(() => {
    getContacts({
      onCompleted: (data) => {
        setContacts(data);
      },
      onError: () => {
        //
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <TableContainer bg={"white"} borderRadius={"0.625rem"}>
      <Table variant="simple">
        <TableCaption>Contacts</TableCaption>
        <Thead>
          <Tr>
            <Th color={"black"}>Date</Th>
            <Th color={"black"}>Email</Th>
            <Th color={"black"}>Subject</Th>
            <Th color={"black"}>Title</Th>
            <Th color={"black"}>Message</Th>
          </Tr>
        </Thead>
        <Tbody color={"black"}>
          {contacts.map(({ _id, name, email, subject, message, createdAt }) => (
            <Tr color={"black"} key={_id}>
              <Td>{new Date(createdAt).toDateString()}</Td>
              <Td>{email}</Td>
              <Td>{subject}</Td>
              <Td>{name}</Td>
              <Td>{message}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
export default Contact;
