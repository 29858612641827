import { type IIcons } from "./icon.types";


export const DashboardIcon = ({mode, activeStatus}: IIcons) => {
  const color = {
    light: {
      active: "#040404",
      inactive: "#808080",
    },
    dark: {
      active: "#F5CE6F",
      inactive: "#F5F5F5",
    }
  }[mode][activeStatus];

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M7.37968 0.759494L1.71123 4.34983C0.641711 4.99425 0 6.09896 0 7.29574V17.0541C0 18.7112 1.49733 20 3.42246 
      20H9.51913V12.6349H10.5887V20H16.5775C18.5027 20 20 18.7112 20 17.0541V7.3878C20 6.19102 19.3583 5.08631 18.2888 
      4.34983L12.6203 0.759494C11.016 -0.253165 8.98396 -0.253165 7.37968 0.759494Z" fill={color}/>
    </svg>  
  );
};
