export const ROUTES = {
    login: "/login",
    notFound: "*",
    dashboard: "/",
    overview: "/",
    contacts: "/contacts",
    templateEditor: "template-editor",
    templates: "/templates",
    templatesBin: "/templates/bin",
    users: "/users",
    admins: "/admins",
    pageViews: "/page-views",
    linkClicks: "/link-clicks",
    feedback: "/feedback"
  };