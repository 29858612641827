import theme from 'theme'
import {
  Button as ChakraButton,
  type ButtonProps,
  useColorModeValue,
} from '@chakra-ui/react'
import { type FC } from 'react'

export interface IButtonProps extends ButtonProps {
  margin?: string
  padding?: string
  isDisabled?: boolean
  fontSize?: any
  backgroundColor?: string
  backgroundImage?: string
  color?: string
  borderRadius?: any
  fontWeight?: any
  opacity?: string
  size?: 'lg' | 'md' | 'sm' | 'xs'
  border?: string
  width?: string
  height?: string
  maxWidth?: any
  textHoverColor?: string
  backgroundHoverColor?: string
  borderHoverState?: string
  type?: 'button' | 'submit' | 'reset' | undefined
  onClick?: (e?: any) => void
  text: any
  _btnActive?: any
  _btnHover?: any
  boxShadow?: any
}

const Button: FC<IButtonProps> = ({
  margin,
  padding,
  size,
  color,
  backgroundColor,
  isDisabled,
  fontSize,
  borderRadius,
  fontWeight,
  opacity,
  border,
  width,
  maxWidth,
  height,
  textHoverColor,
  backgroundHoverColor,
  borderHoverState,
  type,
  onClick,
  text,
  boxShadow,
  _btnActive,
  _btnHover,
  ...rest
}: IButtonProps) => {
  const defaults = {
    bg: useColorModeValue(
      theme.colors.button.bg.primary,
      theme.colors.button.bg.secondary,
    ),
    color: useColorModeValue(
      theme.colors.button.color.primary,
      theme.colors.button.color.secondary,
    ),
    boxShadow:
      'rgba(255,255,255,.02) 1px 1px 2px 3px, rgba(255,255,255,.08) 1px 1px 2px 3px',
    hover: {
      bg: useColorModeValue(
        theme.colors.button.bg.primary,
        theme.colors.button.bg.secondary,
      ),
      color: useColorModeValue(
        theme.colors.button.color.primary,
        theme.colors.button.color.secondary,
      ),
      opacity: 0.7,
    },
    active: {
      bg: useColorModeValue(
        theme.colors.button.bg.primary,
        theme.colors.button.bg.secondary,
      ),
      color: useColorModeValue(
        theme.colors.button.color.primary,
        theme.colors.button.color.secondary,
      ),
      opacity: 0.7,
    },
  }
  return (
    <ChakraButton
      _focus={{ outline: 'none', boxShadow: 'none' }}
      _disabled={{ opacity: 0.5, cursor: 'not-allowed' }}
      type={type ?? 'submit'}
      margin={margin ?? '0'}
      padding={padding ?? '1rem 2rem'}
      size={size ?? 'md'}
      outline="none"
      color={color ?? defaults.color}
      backgroundColor={backgroundColor ?? defaults.bg}
      isDisabled={isDisabled}
      fontSize={fontSize ?? '15px'}
      borderRadius={borderRadius ?? '16px'}
      fontWeight={fontWeight ?? 400}
      opacity={opacity ?? '1'}
      border={border ?? 'none'}
      width={width ?? 'fit-content'}
      height={height ?? '60px'}
      maxWidth={maxWidth ?? 'fit-content'}
      boxShadow={boxShadow ?? defaults.boxShadow}
      _hover={
        _btnHover ?? {
          color: `${textHoverColor ?? defaults.color}`,
          backgroundColor: `${backgroundHoverColor ?? defaults.bg}`,
          border: `${borderHoverState ?? 'none'}`,
          opacity: defaults.hover.opacity,
        }
      }
      _active={
        _btnActive ||
        ({
          color: `${textHoverColor}`,
          backgroundColor: `${backgroundHoverColor ?? defaults.bg}`,
          border: `${borderHoverState ?? 'none'}`,
        } ??
          defaults.active)
      }
      onClick={onClick}
      {...rest}
    >
      {text}
    </ChakraButton>
  )
}

export default Button
