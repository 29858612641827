import { generateAPI } from "apis/api.generator";
import { Admin } from "types/Admin";

export const createSuperOperator = generateAPI<
  {
    body: {
      firstname: string;
      lastname: string;
      email: string;
      password: string;
    }
  }, 
  Admin[]
>("post", "auth/create-super-operator");
