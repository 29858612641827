
import { EditorIcon } from './EditorIcon';
import { TemplatesIcon } from './TemplatesIcon';
import { DashboardIcon } from './DashboardIcon';
import { type IIcons } from './icon.types';


export const iconsMap: Record<
  string, 
  (mode: IIcons) => JSX.Element
> = {
  'Dashboard': DashboardIcon, // yagni
  'Templates': TemplatesIcon,
  'Editor': EditorIcon,
};
