import { signal } from "@preact/signals-react";
import { AdminPermission } from "types/Admin";


export const AdminPermissionMatrix :
  {[key in AdminPermission]: boolean}
= {
  [AdminPermission.VIEW_DASHBOARD_METRICS]: false,
  [AdminPermission.VIEW_USERS_COUNT]: false,
  [AdminPermission.VIEW_USERS_LIST]: false,
  [AdminPermission.VIEW_FEEDBACK]: false,
  [AdminPermission.VIEW_TEMPLATES]: false,
  [AdminPermission.CREATE_TEMPLATE]: false,
  [AdminPermission.CLONE_TEMPLATE]: false,
  [AdminPermission.DELETE_OWN_TEMPLATE]: false,
  [AdminPermission.DELETE_TEMPLATE]: false,
  [AdminPermission.CREATE_OPERATOR]: false,
  [AdminPermission.CREATE_SUPER_OPERATOR]: false,
  [AdminPermission.CREATE_ADMIN]: false,
};

export const AdminPermissionMatrixStore = signal({...AdminPermissionMatrix});
