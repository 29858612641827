import {
  Avatar,
  Button,
  Flex,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  // Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { getAllAdmins } from "apis/admin/get.all.admins";
import { useEffect, useState } from "react";
import { ResponsiveModal } from "components";
import CreateAdminModal from "components/AppModals/CreateAdminModal";
import { adminStore } from "utils/store/admins";
import { AdminRole, prettyAdminRole } from "types/Admin";
import ChangeAdminRoleModal from "components/AppModals/ChangeAdminRoleModal";


const AdminCmp = () => {

  const [createAdminModalOpen, setCreateAdminModalOpen] = useState(false);
  const toggleCreateAdminModal = () => {
    setCreateAdminModalOpen(!createAdminModalOpen);
  };
  const [changeAdminRoleModalOpen, setChangeAdminRoleModalOpen] = useState(false);
  const toggleChangeAdminRoleModal = () => {
    setChangeAdminRoleModalOpen(!changeAdminRoleModalOpen);
  };
  const [currentAdmin, setCurrentAdmin] = useState(
    {
      adminID: "",
      adminRole: AdminRole.OPERATOR,
    }
  );

  useEffect(() => {
    getAllAdmins({
      onCompleted: (data) => {
        adminStore.value = {
          ...adminStore.value,
          admins: data,
        };
      },
      onError: () => {
        //
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      flexDirection={"column"}
      gap={"15px"}
    >
      <Button
        h={"50px"}
        w={"150px"}
        onClick={() => {
          setCreateAdminModalOpen(true)
        }}
        border={"1px solid black"}
      >
        Create Admin
      </Button>
      <TableContainer bg={"white"} borderRadius={"0.625rem"}>
        <Table variant="simple">
          <TableCaption>Admins</TableCaption>
          <Thead>
            <Tr>
              <Th color={"black"}>Avatar</Th>
              <Th color={"black"}>Email</Th>
              <Th color={"black"}>Firstname</Th>
              <Th color={"black"}>Lastname</Th>
              <Th color={"black"}>Role</Th>
              <Th color={"black"}></Th>
            </Tr>
          </Thead>
          <Tbody color={"black"}>
            {adminStore.value.admins.map(({ _id, email, firstname, lastname, avatar, role }) => (
              <Tr color={"black"} key={_id}>
                <Td>
                  <Avatar 
                    name={`${firstname} ${lastname}`}
                    src={avatar}
                  />
                </Td>
                <Td>{email}</Td>
                <Td>{firstname}</Td>
                <Td>{lastname}</Td>
                <Td>{prettyAdminRole[role]}</Td>
                <Td>
                  <Button
                    h={"40px"}
                    w={"100px"}
                    onClick={() => {
                      setCurrentAdmin({
                        adminID: _id,
                        adminRole: role,
                      });
                      setChangeAdminRoleModalOpen(true);
                    }}
                    border={"1px solid black"}
                    color={"black"}
                    fontSize={"14px"}
                  >
                    Change Role
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <ResponsiveModal
        isOpen={createAdminModalOpen}
        toggle={toggleCreateAdminModal}
        modalType="create template modal"
        maxWidth="30rem"
        content={
          <CreateAdminModal 
            onClose={toggleCreateAdminModal} 
          />
        }
      />
      <ResponsiveModal
        isOpen={changeAdminRoleModalOpen}
        toggle={toggleChangeAdminRoleModal}
        modalType="change admin role modal"
        maxWidth="30rem"
        content={
          <ChangeAdminRoleModal 
            adminID={currentAdmin.adminID}
            adminRole={currentAdmin.adminRole}
            onClose={toggleChangeAdminRoleModal}
          />
        }
      />
    </Flex>
  );
};
export default AdminCmp;
