
export type Admin = {
  _id: string;
  firstname: string;
  lastname: string;
  username?: string;
  phone?: string;
  email: string;
  password: string;
  DOB?: Date;
  avatar?: string;
  role: AdminRole;
  permissions: AdminPermission[];
};


export enum AdminRole {
  OPERATOR = "operator",
  SUPER_OPERATOR = "super_operator",
  ADMIN = "admin",
  SUPPER_ADMIN = "super_admin",
}

export enum AdminPermission {
  VIEW_DASHBOARD_METRICS = "p_bo_can_view_dashboard_metrics",
  VIEW_USERS_COUNT = "p_bo_can_view_users_count",
  VIEW_USERS_LIST = "p_bo_can_view_users_list",
  VIEW_FEEDBACK = "p_bo_can_view_feedback",
  VIEW_TEMPLATES = "p_bo_can_view_templates",
  CREATE_TEMPLATE = "p_bo_can_create_template",
  CLONE_TEMPLATE = "p_bo_can_clone_template",
  DELETE_OWN_TEMPLATE = "p_bo_can_delete_own_template",
  DELETE_TEMPLATE = "p_bo_can_delete_template",
  CREATE_OPERATOR = "p_bo_can_create_operator",
  CREATE_SUPER_OPERATOR = "p_bo_can_create_super_operator",
  CREATE_ADMIN = "p_bo_can_create_admin",
}


export const prettyAdminRole: Record<AdminRole, string> = {
  [AdminRole.ADMIN]: "admin",
  [AdminRole.SUPPER_ADMIN]: "super admin",
  [AdminRole.SUPER_OPERATOR]: "super operator",
  [AdminRole.OPERATOR]: "operator",
};
