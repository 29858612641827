import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Flex, SystemProps, Text } from "@chakra-ui/react";

interface InfoCardProps extends SystemProps{
  title: string;
  value: number | string;
  cardClick?: () => void;
}

const InfoCard = ({ title, value, cardClick, ...props }: InfoCardProps) => {
  return (
    <Flex
      flexDir={"column"}
      w={"120px"}
      h={"100px"}
      bg={"white"}
      p={"0.75rem"}
      borderRadius={"0.625rem"}
      pos={"relative"}
      {...(cardClick ? {onClick: cardClick} : {})}
      {...props}
    >
      <Text fontSize={"0.625rem"} color={'black'}>{title}</Text>
      <Text fontSize={"2.5rem"} color={'black'}>{value}</Text>
      {cardClick && (
        <Box pos={"absolute"} bottom={'2px'} right={'4px'} onClick={cardClick}>
          <ExternalLinkIcon color={'black'} />
        </Box>
      )}
    </Flex>
  );
};

export default InfoCard;
