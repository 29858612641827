import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { type FC } from "react";
import { Individual } from "types/Individual";

interface profileProps {
  isOpen: boolean;
  onClose: () => void;
  individual: Individual;
}

const Profile: FC<profileProps> = ({
  isOpen,
  onClose,
  individual,
}) => {

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="none"
      isCentered={true}
      closeOnOverlayClick={true}
    >
      <ModalOverlay
        bg="rgba(34,36,44,.2)"
        backdropFilter="blur(10px)"
      />
      <ModalContent>
        <ModalBody>
          <Text>Profile</Text>
          <Image 
            objectFit={"cover"}
            src={individual.avatar}
            w={"150px"}
            h={"150px"}
          />

          <Text>firstname: {individual.firstname}</Text>
          <Text>username: {individual.username}</Text>
          <Text>email: {individual.email}</Text>
          <Text
            textDecoration={
              individual.isCompletePortfolio ?
              "line-through" :
              "none"
            }
          >completed portfolio</Text>
          <Text
            textDecoration={
              individual.isPeigePublished ?
              "line-through" :
              "none"
            }
          >published peige</Text>
          <Text
            textDecoration={
              individual.isPeigeShared ?
              "line-through" :
              "none"
            }
          >shared peige</Text>
          <Text
            textDecoration={
              individual.hasSentFeedback ?
              "line-through" :
              "none"
            }          
          >sent feedback</Text>

          <Text>profession: {individual?.profile?.profession}</Text>
          <Text>interests: {String(individual?.profile?.interests)}</Text>
          <Text>birthday: {
          individual?.profile?.birthday ? 
            new Date(individual?.profile?.birthday).toDateString() :
            ""
        }</Text>
          <Text
            textDecoration={
              individual.hasSentFeedback ?
              "line-through" :
              "none"
            }          
          >business</Text>
          <Text>gender: {individual?.profile?.gender}</Text>


        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default Profile;
