import { generateAPI } from "apis/api.generator";

export const editTemplate = generateAPI<
  {
    body: {
      templateID: string;
      templateName: string;
      description: string;
      isInApp: boolean;
      coverImage: string;
    };
  },
  {
    templateID: string;
    templateName: string;
    description: string;
    isInApp: boolean;
    coverImage: string;
    shortID: string;
  }[]
>("put", "template");
