import { Box, useColorMode, useMediaQuery } from "@chakra-ui/react";
import { Topbar, Content, BottomNav } from "../../parts";
import { useLocation } from "react-router-dom";
import { type BodyInterface } from "./types";
import { useContext, type FC } from "react";
import { CurrentUserContext } from 'routes/AuthRoutesProvider';

const Body: FC<BodyInterface> = ({ openRoute, username }: BodyInterface) => {
  const { currentUser, appState } = useContext(CurrentUserContext)

  const { pathname } = useLocation();
  const { colorMode } = useColorMode();

  const isEditor = pathname === "/template-editor";

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return (
    <Box w={"full"} h={"100dvh"}>
      <Topbar
        isEditor={isEditor}
        colorMode={colorMode}
        isWeb={isLargerThan768}
        username={username}
      />
      <Content isEditor={isEditor} />
      <BottomNav
        avatar={currentUser.avatar}
        colorMode={colorMode}
        isEditor={isEditor && appState.viewContext === 'Editor'}
        isWeb={isLargerThan768}
        openRoute={openRoute}
      />
    </Box>
  );
};

export default Body;
