import { Flex, IconButton } from "@chakra-ui/react";
import { type FC } from "react";
import theme from 'theme';
import { type INavIconButton } from "typings/interface";

const NavIconButton: FC<INavIconButton> = ({
  active,
  flexDir,
  open,
  icon,
  iconLabel,
  ...rest
}: INavIconButton) => {
  const activeColor = active
    ? theme.colors.default.orange
    : theme.colors.default.black;
  return (
    <Flex flexDir={flexDir} onClick={open} m={{ sm: "0 5px" }}>
      <IconButton
        height={5}
        mb={0.5}
        variant={"ghost"}
        aria-label="open menu"
        icon={icon}
        color={activeColor}
        _hover={{
          bg: "none",
        }}
        {...rest}
      />
    </Flex>
  );
};

export default NavIconButton;
