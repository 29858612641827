import { signal } from "@preact/signals-react";

export type TemplateStoreCollection = {
  templates: {
    templateID: string;
    templateName: string;
    description: string;
    isInApp: boolean;
    coverImage: string;
    shortID: string;
  }[];
  currentTemplateID: string;
};

export const templatesStore = signal<TemplateStoreCollection>({
  templates: [],
  currentTemplateID: "",
});

export const setCurrentTemplate = (
  {templateName, templateID, shortID} : {
    templateName: string, 
    templateID: string, 
    shortID: string
  }
) => {
  templatesStore.value = {
    ...templatesStore.value,
    currentTemplateID: templateID,
  };
  CurrentTemplate.value = {
    templateName,
    templateID,
    shortID,
  };
};

export type TemplateBinStoreCollection = {
  templates: {
    templateID: string;
    templateName: string;
    description: string;
    isInApp: boolean;
    coverImage: string;
    daysLeft: number;
  }[];
  currentTemplateID: string;
};

export const templatesBinStore = signal<TemplateBinStoreCollection>({
  templates: [],
  currentTemplateID: "",
});

export const setCurrentBinTemplateID = (templateID: string) => {
  templatesBinStore.value = {
    ...templatesBinStore.value,
    currentTemplateID: templateID,
  };
};

export const CurrentTemplate = signal<
  {
    templateName: string, 
    templateID: string, 
    shortID: string
  }
>(
  {
    templateName: '', 
    templateID: '', 
    shortID: '',
  }
);
