import {
  Box,
  Textarea as TextareaCmp,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import './textarea.css'
import { type ITextArea } from 'typings/interface'
import { type FC } from 'react'

const TextArea: FC<ITextArea> = ({
  placeholder,
  id,
  className,
  tAType,
  background,
  border,
  required,
  name,
  disabled,
  value,
  values,
  onChange,
  errorMessage,
  showMessage,
  color,
  width,
  borderRadius,
  hideCaret,
  _focus,
  label,
  ...rest
}: ITextArea) => {
  const { colorMode } = useColorMode()

  return (
    <>
      <Box
        position={'relative'}
        display="flex"
        flexDirection={'column'}
        outline="none"
        borderRadius={borderRadius ?? '8px'}
        w={width ?? 'full'}
        background={background ?? 'transparent'}
        border={
          border || `1px solid ${
                colorMode === 'light'
                  ? 'rgba(0, 0, 0, 0.5)'
                  : 'rgba(255, 255, 255, 0.5)'
              }`
        }
        overflow={'hidden'}
      >
        <TextareaCmp
          sx={{ caretColor: `${hideCaret ? 'transparent' : ''}` }}
          id={id}
          className={className ?? tAType ?? ''}
          autoComplete="off"
          padding={'1rem'}
          background={'transparent'}
          placeholder={placeholder}
          required={required ?? false}
          name={name}
          disabled={disabled ?? false}
          value={values ? values[name] : value}
          onChange={(e: any) => {
            onChange?.(e)
          }}
          border={0}
          _hover={{
            border: 0,
          }}
          //   _focus={{
          //     boxShadow: "none",
          //     border: errorMessage
          //       ? "0"
          //       : _focus
          //       ? _focus
          //       : `0.5px solid ${theme.colors.light.vLightGray}`,
          //     borderRadius: borderRadius ?? "8px",
          //   }}
          _disabled={{ opacity: 0.9 }}
          fontSize={'16px'}
          color={color ?? `${colorMode === 'light' ? 'black' : 'white'}`}
          fontWeight="400"
          {...rest}
        />
      </Box>
      <Box textAlign={'left'} padding="0.3rem 0">
        <Text color="#FF8585" fontSize={'12px'} fontWeight="400" pl="1rem">
          {showMessage ? errorMessage : ''}
        </Text>
      </Box>
    </>
  )
}

export default TextArea
