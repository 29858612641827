
export enum Gender {
  MALE = "male",
  FEMALE = "female",
  OTHERS = "others",
}

export enum AccountStatus {
  BANNED = "banned",
  SUSPENDED = "suspended",
  ACTIVE = "active",
  INACTIVE = "inactive",
  DEACTIVATED = "deactivated",
}

export type IndividualProfile = {
  profession: string;
  interests: string[];
  birthday: Date;
  isBusiness: boolean;
  gender: Gender;
};

export type Individual = {
  firstname: string;
  lastname: string;
  fullname: string;
  username: string;
  peigeID: string;
  lastUsernameUpdateDate?: Date;
  phone?: string;
  email: string;
  password: string;
  DOB?: Date;
  avatar?: string;
  favicon: string;
  meta: {
    title: string;
    description: string;
  };
  status: AccountStatus;
  isNewUser: boolean;
  isVerified: boolean;
  isBusiness: boolean;
  shouldCloseOnboardingModal: boolean;
  isDeactivatedAccount: boolean;
  googleSocialID: string;
  profile: IndividualProfile;
  isCompletePortfolio: boolean;
  isPeigePublished: boolean;
  isPeigeShared: boolean;
  hasSentFeedback: boolean;
}


export const SampleIndividual: Individual = {
  firstname: "",
  lastname: "",
  fullname: "",
  username: "",
  peigeID: "",
  lastUsernameUpdateDate: new Date(),
  phone: "",
  email: "",
  password: "",
  DOB: new Date(),
  avatar: "",
  favicon: "",
  meta: {
    title: "",
    description: "",
  },
  status: AccountStatus.ACTIVE,
  isNewUser: false,
  isVerified: false,
  isBusiness: false,
  shouldCloseOnboardingModal: false,
  isDeactivatedAccount: false,
  googleSocialID: "",
  profile: {
    profession: "",
    interests: [],
    birthday: new Date(),
    isBusiness: false,
    gender: Gender.MALE,
  },
  isCompletePortfolio: false,
  isPeigePublished: false,
  isPeigeShared: false,
  hasSentFeedback: false,
};
