import { MenuItem, useColorMode } from "@chakra-ui/react";
import { type FC } from "react";
import { type IMenuItemCmp } from "typings/interface";

const MenuItemCmp: FC<IMenuItemCmp> = ({ name, onClick, onPromiseClick, username }: IMenuItemCmp) => {
  const { colorMode } = useColorMode();
  return (
    <MenuItem
      bg={
        colorMode === "light"
          ? "background.primary.default"
          : "background.primary._dark"
      }
      color={
        colorMode === "light" ? "text.primary._dark" : "text.primary.default"
      }
      _hover={{ bg: "transparent" }}
      _active={{ bg: "transparent" }}
      _focus={{ bg: "transparent" }}
      justifyContent={username ? "center" : "flex-start"}
      onClick={onClick ?? onPromiseClick}
      closeOnSelect
    >
      {name}
    </MenuItem>
  );
};

export default MenuItemCmp;
