import { Flex } from '@chakra-ui/react'
import { Nav } from 'Layout/Dashboard/composition'
import AvatarMenu from 'Layout/Dashboard/composition/AvatarMenu'
import LogoSection from 'Layout/Dashboard/composition/LogoSection'
import { type FC } from 'react'
import theme from 'theme'
import { type GeneralNavInterface } from 'typings/interface'

const Sidebar: FC<GeneralNavInterface> = ({
  avatar,
  colorMode,
  isEditor,
  openRoute,
  username,
}: GeneralNavInterface) => {

  const darkBg = theme.colors.background.primary._dark;
  const lightBg = theme.colors.background.primary.default;
  return (
    <Flex
      display={{ base: 'none', md: 'flex' }}
      top={0}
      pos={'sticky'}
      zIndex={10}
      overflowY={'auto'}
      flexDir={'column'}
      p={'1rem'}
      h={'100dvh'}
      border={`0.5px solid ${
        colorMode === 'light'
          ? 'rgba(0, 0, 0, 0.05)'
          : 'rgba(255, 255, 255, 0.05)'
      }`}
      bg={
        isEditor ? 'transparent' : colorMode === 'light' ? lightBg : darkBg
      }
    >
      <Flex
        h={'full'}
        justify={'space-between'}
        align={'center'}
        flexDir={'column'}
        overflow={'hidden'}
      >
        <LogoSection colorMode={colorMode} />
        <Nav openRoute={openRoute} order={'column'} />
        <AvatarMenu
          avatar={avatar}
          username={username}
          openRoute={openRoute}
        />
      </Flex>
    </Flex>
  )
}

export default Sidebar
