import {
  Flex,
  Text,
  useToast,
} from '@chakra-ui/react'

// import { updateCurrentUser } from 'hooks/useCurrentUser'
import { useState, type FC } from 'react'
// import { type GetIndividualV2 } from 'routes/AuthRoutesProvider'
// import { POST } from 'utils/base.api'
import Button from 'components/Button'
import { PlainInput } from 'components/Input'
import { templatesStore } from 'utils/store/templates'
import { modifyPeigeFromTemplate } from 'apis/templates/modify.peige.from.template'


interface ILinkTemplateToPeigeModal {
  toggleModal: () => void;
  // templa: string
  // maxWidth: any
}

const LinkTemplateToPeigeModal: FC<ILinkTemplateToPeigeModal> = ({toggleModal}) => {
  const toast = useToast({
    position: 'top',
    containerStyle: {
      zIndex: 9,
    },
  })
  const [ peigeUsername, setPeigeUsername ] = useState("");

  const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    if (e.target.value.length <= 20) {
      setPeigeUsername(
        e.target.value
          .toLowerCase()
          .replace(/\s+/g, '')
          .replace(/[^a-zA-Z0-9]/g,'')
      );
    }
  };

  return (
    <Flex
      flexDir={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      fontFamily={'DM Sans, sans-serif'}
      pt={'0.5rem'}
      pb={'1.3rem'}
      // maxW={{base:'26.75rem', lg:'30rem'}}
      maxH={{ base: '20.375rem', md: '21.25rem' }}
    >
      <Text fontSize="1rem" fontWeight={700} mb={'2rem'}>
        Clone template
      </Text>
      <Flex
        w={'100%'}
        flexDir={'column'}
        // mt={'1.88rem'}
        gap={'0.62rem'}
        justifyContent={'center'}
        alignItems={"center"}
      >
        <Flex
          py="0.75rem"
          pr={'1.38rem'}
          pl={{ base: '1rem', md: '1.12rem' }}
          borderRadius="0.5rem"
          maxWidth={{ base: '23rem', md: '25rem' }}
          h={'3rem'}
          w="100%"
          justifyContent={'space-between'}
          alignItems={'center'}
          m={'auto'}
        >
          <PlainInput 
            name={"username"}
            placeholder='Enter peige username'
            value={peigeUsername}
            onChange={handleUsernameChange}
          />
        </Flex> 

        <Button
          text={"link"}
          isDisabled={!(peigeUsername)}
          onClick={() => {
            modifyPeigeFromTemplate({
              body: {
                templateID: templatesStore.value.currentTemplateID,
                peigeUsername,
              },
              onCompleted(data) {
                toast({
                  status: 'success',
                  description: "peige modified",
                });
                toggleModal()
              },
              onError(err) {
                toast({
                  status: 'error',
                  description: err,
                })                  
              }
            });
          }}
        />
      </Flex>
    </Flex>
  )
}

export default LinkTemplateToPeigeModal;
