export const colors = {
  text: {
    primary: {
      default: '#FFFFFF',
      _dark: '#010101',
    },
    settings: {
      light: '#6C6464',
      dark: '#C4C4C4',
    },
  },
  background: {
    primary: {
      default: '#F1F1F1',
      _dark: '#111111',
    },
    secondary: {
      default: '#FEFEFE',
      _dark: '#333333',
    },
    tertiary: {
      default: '#F9F9F9',
      _dark: '#222222',
    },
  },
  socialBtn: {
    bg: {
      light: '#DAD9D9',
      dark: '#222222',
    },
    text: {
      light: '#040404',
      dark: '#F5F5F5',
    },
  },
  shareModal: {
    darkText: '#040404',
    lightText:'#f5f5f5',
    light: {
      iconBg: '#E7E6E6',
    },
    dark: {
      iconBg: '#333333',
      linkBg: '#222',
    },
  },
  border: {
    default: 'transparent',
    _dark: '#00000040',
  },
  button: {
    bg: {
      primary: '#000000',
      secondary: '#F5CE6F',
    },
    color: {
      primary: '#F5CE6F',
      secondary: '#000000',
    },
    boxShadow:
      'rgba(255,255,255,.02) 1px 1px 2px 3px, rgba(255,255,255,.08) 1px 1px 2px 3px',
    hover: {
      bg: {
        primary: '#000000',
        secondary: '#F5CE6F',
      },
      color: {
        primary: '#F5CE6F',
        secondary: '#000000',
      },
      opacity: 0.7,
    },
    active: {
      bg: {
        primary: '#000000',
        secondary: '#F5CE6F',
      },
      color: {
        primary: '#F5CE6F',
        secondary: '#000000',
      },
      opacity: 0.7,
    },
  },
  default: {
    black: '#111111',
    white: '#F1F1F1',
    orange: '#FF5403',
    gray: '#525252',
    error: '#EB001B',
    blue: '#90cdf4',
    yellow: '#f5ce6fcc',
    deepYellow: '#F5CE6F',
    brown: '#A69774',
    green: '#89E493',
    darkGray: '#999999',
    red: '#D13D4E',
    blackSec: '#000000',
    greenSec: '#4A8F51',
    lightYellow: '#D1A22E',
    graySec: '#A29B89',
    yellowOpacity: '#f5ce6fb2',
  },
  modal: {
    light: {
      color: '#040404',
      bg: '#F1F1F1',
      subnav: '#FFFFFF',
      actionSection: {
        bg: '#F1F1F1',
        color: '#040404',
      },
      headerSubnav: {
        bg: '#F1F1F1',
        color: '#040404',
      },
      textEditor: {
        bg: '#F9F9F9',
      },
      grey900: '#999',
      iconsBorder: '#393939',
    },
    dark: {
      color: '#F5F5F5',
      bg: '#040404',
      subnav: '#222',
      actionSection: {
        bg: '#333333',
        color: '#FFFFFF',
      },
      headerSubnav: {
        bg: '#111111',
        color: '#F5F5F5',
      },
      textEditor: {
        bg: '#222',
      },
      grey900: '#393939',
      iconsBorder: '#fff',
    },
  },
}
