import { generateAPI } from "apis/api.generator";


export const permanentlyDeleteAllTemplates = generateAPI<
  any,
  {
    deleted: {
      templateID: string;
      templateName: string;
      description: string;
      isInApp: boolean;
      coverImage: string;
      daysLeft: number;
    }[];
    active: {
      templateID: string;
      templateName: string;
      description: string;
      isInApp: boolean;
      shortID: string;
      coverImage: string;
      daysLeft: number;
    }[];
  }
>("post", "template/admin/permanently-delete-all");
