import { type FC } from 'react'
import { useLocation } from 'react-router-dom'
import { forwardRef, useColorMode } from '@chakra-ui/react'

import { capitaliseString } from 'utils/fns'
import { iconsMap } from './icons/icon.map'
import { type INavIcon } from 'typings/interface'


const NavIcon: FC<INavIcon> = forwardRef<INavIcon, 'img'>((props, ref) => {
  const { colorMode } = useColorMode()
  const { pathname } = useLocation()

  const location = pathname.substring(1)
      ? capitaliseString(pathname.substring(1))
      : 'Dashboard'

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const Icon = iconsMap[props.navname!];

  return (
    <Icon 
      mode={colorMode}
      activeStatus={props.navname === location ? 'active' : 'inactive'}
    />
  )
})

export default NavIcon
